export default {
  default: {
    dark: {
      textbase: '#edf0f2',
      background: '#05090c',
      surface: '#272727', // '#111b27',
      primary: '#009fb7', //'#0096c7',
      secondary: '#696773', //'#2a4f66',
      accent: '#fed766', // '#59bbbb',
      error: '#FF5252',
      info: '#2196F3',
      success: '#05c075',
      warning: '#FFC107',
      timeline: '#272727',
      timelineBg: '#141414',
      customBg: '#1B1C1C',
      customDrawerBg: '#1B1C1C',
      accentText: '#222222',
      mermaidFontColor: '#333',
      mermaidIconColor: '#009fb7',
      mermaid: {
        backgroundColor: '#ECECFF',
        strokeColor: '#009fb7',
        endNodeStroke: '#696773',
        linkLineColor: '#009fb7'
      },
      tableBorderLinesColor: '#696773',
      customInputBorderColor: '#696773',
      customDrawerSelect: '#009fb7',
      customStepLogSelectBg: '#009fb7',
      customStepLogSelect: '#272727'
    },

    // light theme colors
    light: {
      textbase: '#222222',
      background: '#ffffff',
      surface: '#eff1f3', // '#f2f5f8',
      primary: '#009fb7', //'#0096c7',
      secondary: '#696773', //'#2a4f66',
      accent: '#fed766', // '#59bbbb',
      error: '#ef476f',
      info: '#2196F3',
      success: '#05c075',
      warning: '#ffd166',
      timeline: '#eff1f3',
      timelineBg: '#ffffff',
      customBg: '#EAEEF0',
      customDrawerBg: '#EAEEF0',
      accentText: '#222222',
      mermaidFontColor: '#333',
      mermaidIconColor: '#009fb7',
      mermaid: {
        backgroundColor: '#ECECFF',
        strokeColor: '#009fb7',
        endNodeStroke: '#696773',
        linkLineColor: '#009fb7'
      },
      tableBorderLinesColor: '#696773',
      customInputBorderColor: '#696773',
      customDrawerSelect: '#009fb7',
      customStepLogSelectBg: '#009fb7',
      customStepLogSelect: '#eff1f3'
    }
  },
  goldenrod: {
    dark: {
      textbase: '#edf0f2',
      background: '#05090c',
      surface: '#272727', // '#111b27',
      primary: '#C58A1B', //'#0096c7',
      secondary: '#394054', //'#2a4f66',
      accent: '#8A7356', // '#59bbbb',
      error: '#FF5252',
      info: '#2196F3',
      success: '#05c075',
      warning: '#FFC107',
      timeline: '#272727',
      timelineBg: '#141414',
      customBg: '#192532',
      customDrawerBg: '#192532',
      accentText: '#222222',
      mermaidFontColor: '#333',
      mermaidIconColor: '#C58A1B',
      mermaid: {
        backgroundColor: '#ECECFF',
        strokeColor: '#C58A1B',
        endNodeStroke: '#394054',
        linkLineColor: '#C58A1B'
      },
      tableBorderLinesColor: '#394054',
      customInputBorderColor: '#394054',
      customDrawerSelect: '#C58A1B',
      customStepLogSelectBg: '#C58A1B',
      customStepLogSelect: '#272727'
    },

    // light theme colors
    light: {
      textbase: '#222222',
      background: '#ffffff',
      surface: '#eff1f3', // '#f2f5f8',
      primary: '#B07507', //'#0096c7',
      secondary: '#3A3238', //'#2a4f66',
      accent: '#FFE45E', // '#59bbbb',
      error: '#ef476f',
      info: '#2196F3',
      success: '#05c075',
      warning: '#ffd166',
      timeline: '#eff1f3',
      timelineBg: '#ffffff',
      customBg: '#F2F4F8',
      customDrawerBg: '#F2F4F8',
      accentText: '#222222',
      mermaidFontColor: '#333',
      mermaidIconColor: '#B07507',
      mermaid: {
        backgroundColor: '#ECECFF',
        strokeColor: '#B07507',
        endNodeStroke: '#3A3238',
        linkLineColor: '#B07507'
      },
      tableBorderLinesColor: '#3A3238',
      customInputBorderColor: '#3A3238',
      customDrawerSelect: '#C58A1B',
      customStepLogSelectBg: '#C58A1B',
      customStepLogSelect: '#eff1f3'
    }
  },
  magenta: {
    dark: {
      textbase: '#edf0f2',
      background: '#05090c',
      surface: '#272727', // '#111b27',
      primary: '#A3006A', //'#0096c7',
      secondary: '#A39BA8', //'#2a4f66',
      accent: '#4D7298', // '#59bbbb',
      error: '#FF5252',
      info: '#2196F3',
      success: '#05c075',
      warning: '#FFC107',
      timeline: '#272727',
      timelineBg: '#141414',
      customBg: '#14000E',
      customDrawerBg: '#30011E',
      accentText: '#222222',
      mermaidFontColor: '#333',
      mermaidIconColor: '#A3006A',
      mermaid: {
        backgroundColor: '#ECECFF',
        strokeColor: '#A3006A',
        endNodeStroke: '#A39BA8',
        linkLineColor: '#A3006A'
      },
      tableBorderLinesColor: '#A39BA8',
      customInputBorderColor: '#A39BA8',
      customDrawerSelect: '#A3006A',
      customStepLogSelectBg: '#A3006A',
      customStepLogSelect: '#272727'
    },

    // light theme colors
    light: {
      textbase: '#222222',
      background: '#ffffff',
      surface: '#eff1f3', // '#f2f5f8',
      primary: '#672A4E', //'#0096c7',
      secondary: '#820B8A', //'#2a4f66',
      accent: '#5EDDFD', // '#59bbbb',
      error: '#ef476f',
      info: '#2196F3',
      success: '#05c075',
      warning: '#ffd166',
      timeline: '#eff1f3',
      timelineBg: '#ffffff',
      customBg: '#FFD6F3',
      customDrawerBg: '#FFEBF9',
      accentText: '#222222',
      mermaidFontColor: '#333',
      mermaidIconColor: '#672A4E',
      mermaid: {
        backgroundColor: '#ECECFF',
        strokeColor: '#672A4E',
        endNodeStroke: '#820B8A',
        linkLineColor: '#672A4E'
      },
      tableBorderLinesColor: '#820B8A',
      customInputBorderColor: '#820B8A',
      customDrawerSelect: '#A3006A',
      customStepLogSelectBg: '#A3006A',
      customStepLogSelect: '#eff1f3'
    }
  },
  violet: {
    dark: {
      textbase: '#edf0f2',
      background: '#05090c',
      surface: '#272727', // '#111b27',
      primary: '#A356F0', //'#0096c7',
      secondary: '#594771', //'#2a4f66',
      accent: '#FFC759', // '#59bbbb',
      error: '#FF5252',
      info: '#2196F3',
      success: '#05c075',
      warning: '#FFC107',
      timeline: '#272727',
      timelineBg: '#141414',
      customBg: '#271F30',
      customDrawerBg: '#501D86',
      accentText: '#222222',
      mermaidFontColor: '#333',
      mermaidIconColor: '#A356F0',
      mermaid: {
        backgroundColor: '#ECECFF',
        strokeColor: '#A356F0',
        endNodeStroke: '#594771',
        linkLineColor: '#A356F0'
      },
      tableBorderLinesColor: '#594771',
      customInputBorderColor: '#594771',
      customDrawerSelect: '#A356F0',
      customStepLogSelectBg: '#A356F0',
      customStepLogSelect: '#272727'
    },

    // light theme colors
    light: {
      textbase: '#222222',
      background: '#ffffff',
      surface: '#eff1f3', // '#f2f5f8',
      primary: '#7A14E1', //'#0096c7',
      secondary: '#333333', //'#2a4f66',
      accent: '#F9E784', // '#59bbbb',
      error: '#ef476f',
      info: '#2196F3',
      success: '#05c075',
      warning: '#ffd166',
      timeline: '#eff1f3',
      timelineBg: '#ffffff',
      customBg: '#E5DBE6',
      customDrawerBg: '#D7CFDE',
      accentText: '#222222',
      mermaidFontColor: '#333',
      mermaidIconColor: '#7A14E1',
      mermaid: {
        backgroundColor: '#ECECFF',
        strokeColor: '#7A14E1',
        endNodeStroke: '#333333',
        linkLineColor: '#7A14E1'
      },
      tableBorderLinesColor: '#333333',
      customInputBorderColor: '#333333',
      customDrawerSelect: '#A356F0',
      customStepLogSelectBg: '#A356F0',
      customStepLogSelect: '#eff1f3'
    }
  },
  greenCrayola: {
    dark: {
      textbase: '#edf0f2',
      background: '#05090c',
      surface: '#272727',
      primary: '#b1d738',
      secondary: '#345995',
      accent: '#CBEAA6',
      error: '#ef476f',
      info: '#2196F3',
      success: '#05c075',
      warning: '#FFC107',
      timeline: '#272727',
      timelineBg: '#141414',
      customBg: '#292E24',
      customDrawerBg: '#718A2E',
      accentText: '#222222',
      mermaidFontColor: '#333',
      mermaidIconColor: '#b1d738',
      mermaid: {
        backgroundColor: '#ECECFF',
        strokeColor: '#b1d738',
        endNodeStroke: '#345995',
        linkLineColor: '#b1d738'
      },
      tableBorderLinesColor: '#345995',
      customInputBorderColor: '#345995',
      customDrawerSelect: '#b1d738',
      customStepLogSelectBg: '#b1d738',
      customStepLogSelect: '#272727'
    },

    // light theme colors
    light: {
      textbase: '#222222',
      background: '#ffffff',
      surface: '#eff1f3',
      primary: '#7E9933',
      secondary: '#345995',
      accent: '#03440C',
      error: '#ef476f',
      info: '#2196F3',
      success: '#05c075',
      warning: '#ffd166',
      timeline: '#eff1f3',
      timelineBg: '#ffffff',
      customBg: '#FAFDED',
      customDrawerBg: '#CBEAA6',
      accentText: '#ffffff',
      mermaidFontColor: '#333',
      mermaidIconColor: '#7E9933',
      mermaid: {
        backgroundColor: '#ECECFF',
        strokeColor: '#7E9933',
        endNodeStroke: '#345995',
        linkLineColor: '#7E9933'
      },
      tableBorderLinesColor: '#345995',
      customInputBorderColor: '#345995',
      customDrawerSelect: '#b1d738',
      customStepLogSelectBg: '#b1d738',
      customStepLogSelect: '#272727'
    }
  },
  blue: {
    dark: {
      textbase: '#edf0f2',
      background: '#05090c',
      surface: '#272727',
      primary: '#227fea',
      secondary: '#005a90',
      accent: '#fed766',
      error: '#ef476f',
      info: '#2196F3',
      success: '#05c075',
      warning: '#FFC107',
      timeline: '#272727',
      timelineBg: '#141414',
      customBg: '#87aec4',
      customDrawerBg: '#005a90',
      accentText: '#222222',
      mermaidFontColor: '#333',
      mermaidIconColor: '#227fea',
      mermaid: {
        backgroundColor: '#ECECFF',
        strokeColor: '#227fea',
        endNodeStroke: '#005a90',
        linkLineColor: '#227fea'
      },
      tableBorderLinesColor: '#518cc6',
      customInputBorderColor: '#518cc6',
      customDrawerSelect: '#227fea',
      customStepLogSelectBg: '#005a90',
      customStepLogSelect: '#ECECFF'
    },

    // light theme colors
    light: {
      textbase: '#222222',
      background: '#ffffff',
      surface: '#eff1f3',
      primary: '#0065cd',
      secondary: '#000',
      accent: '#fed766',
      error: '#ef476f',
      info: '#2196F3',
      success: '#05c075',
      warning: '#ffd166',
      timeline: '#eff1f3',
      timelineBg: '#ffffff',
      customBg: '#bfecf2',
      customDrawerBg: '#eaeef0',
      accentText: '#222222',
      mermaidFontColor: '#333',
      mermaidIconColor: '#0065cd',
      mermaid: {
        backgroundColor: '#ECECFF',
        strokeColor: '#0065cd',
        endNodeStroke: '#000',
        linkLineColor: '#0065cd'
      },
      tableBorderLinesColor: '#000',
      customInputBorderColor: '#000',
      customDrawerSelect: '#227fea',
      customStepLogSelectBg: '#227fea',
      customStepLogSelect: '#eff1f3'
    }
  },
  simple: {
    dark: {
      textbase: '#edf0f2',
      background: '#05090c',
      surface: '#272727', // '#111b27',
      primary: '#009fb7', //'#0096c7',
      secondary: '#696773', //'#2a4f66',
      accent: '#fed766', // '#59bbbb',
      error: '#FF5252',
      info: '#2196F3',
      success: '#05c075',
      warning: '#FFC107',
      timeline: '#272727',
      timelineBg: '#141414',
      customBg: '#1B1C1C',
      customDrawerBg: '#1B1C1C',
      accentText: '#222222',
      mermaidFontColor: '#333',
      mermaidIconColor: '#009fb7',
      mermaid: {
        backgroundColor: '#ECECFF',
        strokeColor: '#009fb7',
        endNodeStroke: '#696773',
        linkLineColor: '#009fb7'
      },
      tableBorderLinesColor: '#696773',
      customInputBorderColor: '#696773',
      customDrawerSelect: '#009fb7',
      customStepLogSelectBg: '#009fb7',
      customStepLogSelect: '#272727'
    },

    // light theme colors
    light: {
      textbase: '#222222',
      background: '#ffffff',
      surface: '#eff1f3', // '#f2f5f8',
      primary: '#009fb7', //'#0096c7',
      secondary: '#696773', //'#2a4f66',
      accent: '#fed766', // '#59bbbb',
      error: '#ef476f',
      info: '#2196F3',
      success: '#05c075',
      warning: '#ffd166',
      timeline: '#eff1f3',
      timelineBg: '#ffffff',
      customBg: '#EAEEF0',
      customDrawerBg: '#EAEEF0',
      accentText: '#222222',
      mermaidFontColor: '#333',
      mermaidIconColor: '#009fb7',
      mermaid: {
        backgroundColor: '#ECECFF',
        strokeColor: '#009fb7',
        endNodeStroke: '#696773',
        linkLineColor: '#009fb7'
      },
      tableBorderLinesColor: '#696773',
      customInputBorderColor: '#696773',
      customDrawerSelect: '#009fb7',
      customStepLogSelectBg: '#009fb7',
      customStepLogSelect: '#eff1f3'
    }
  }
}
