module.exports = {
  eventPermission: 'Events permissions are inherited from the process that they trigger.',
  gdprHint: 'Only active objects are included in the following lists.',
  noExceptionMessage: 'No Exception Message',
  noExceptionStackTrace: 'No Exception Stack Trace',
  dataTooLArge: 'The selected entry is too large to display. Would you like to store it as file?',
  pluginPresent:'This module contains plugin. Only install if you trust the source of the module!',
  saveFirst: 'Save first to use this feature!',
  addKeyFirst: 'Please add Key before opening Editor!',
  slackMessage: 'Line breaks will be removed on save',
  noDebugLogData: 'No debug log data available',
  settingsChangedStoreToUpDownload: 'Settings have been changed. Please store module first to re-enable Up/Download.',
  ttlLogsHint: 'Setting the value to 0 means that logs will be cached indefinitely. It is highly recommended to set a different value.',
  subType: 'Object structure is defined by separate validation',
  restoreHint: 'Restore will create a new object with the name "Original Name (Restore)". Note that The original object name is in use and that you will not be able to save it as "Original Name".'
}
