import Vue from 'vue'

// eslint-disable-next-line consistent-return
Vue.filter('javaVariableConventionRules', (value, dollarStartEnforced) => {
  if (!value) return true

  if (dollarStartEnforced) {
    return /^[$.]{2}[a-zA-Z._$0-9[\]]*$/.test(value)
  } else {
    return /^[a-zA-Z_$][a-zA-Z_$0-9[\]]*$/.test(value)
  }
})
