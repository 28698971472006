export default {
  // global theme for the app
  globalTheme: 'light', // light | dark

  // dark theme colors
  dark: {
    textbase: '#edf0f2',
    background: '#05090c',
    surface: '#272727', // '#111b27',
    primary: '#009fb7', //'#0096c7',
    secondary: '#696773', //'#2a4f66',
    accent: '#fed766', // '#59bbbb',
    error: '#FF5252',
    info: '#2196F3',
    success: '#05c075',
    warning: '#FFC107',
    timeline: '#272727',
    timelineBg: '#141414'
  },

  // light theme colors
  light: {
    textbase: '#222222',
    background: '#ffffff',
    surface: '#eff1f3', // '#f2f5f8',
    primary: '#009fb7', //'#0096c7',
    secondary: '#696773', //'#2a4f66',
    accent: '#fed766', // '#59bbbb',
    error: '#ef476f',
    info: '#2196F3',
    success: '#05c075',
    warning: '#ffd166',
    timeline: '#eff1f3',
    timelineBg: '#ffffff'
  },

  // blue theme colors
  blue: {
    textbase: '#000',
    background: '#000',
    surface: '#eff1f3', // '#f2f5f8',
    primary: '#009fb7', //'#0096c7',
    secondary: '#696773', //'#2a4f66',
    accent: '#fed766', // '#59bbbb',
    error: '#ef476f',
    info: '#2196F3',
    success: '#05c075',
    warning: '#ffd166',
    timeline: '#000',
    timelineBg: '#000'
  }
}
