import Vue from 'vue'

Vue.filter('checkComplexRegex', (item) => {
  if (!item) return true

  if (['false', 'true', '', '[]', '{}', 'null'].includes(item)) return true

  const temp1 = item.replace(/ /g, '').replace(/[()]/g, '').split(/[()\-+/*]/).map((x) => x.trim()).filter((y) => y !== '').filter((y) => isNaN(y))

  const temp2 = temp1.filter((z) => /\$/.test(z) ).filter((z) => /'/.test(z) )

  const temp3 = temp1.filter((z) => !(/^[$.]{2}/.test(z)) ).filter((r) => !(/'[^']+'/.test(r)))

  const temp4 = item.split(' ').filter((z) => !['*', '+', '-', '/', '(', ')'].includes(z) ).map((x) => x.replace(/[()\-+/*]/g, '')).filter((y) => isNaN(y)).filter((z) => !(/^[$.]{2}/.test(z)) ).filter((r) => !(/'[^']+'/.test(r)))

  if (item[0] === '\'' && item[item.length - 1] === '\'' && temp2.length === 0) {
    return true
  }

  if (temp4.length > 0) {
    return false
  }

  if (temp2.length > 0) {
    return false
  }

  return !(temp3.length)

})
