<template>
  <v-app>
    <layout>
      <router-view />
    </layout>
  </v-app>
</template>

<script>
import config from './configs'
import Layout from '@/layouts/Layout'
import { getSettingsUsingGET as getSettings } from '@/utils/api'
import { mapActions } from 'vuex'
import router from '@/router'
import axios from 'axios'
import auth from '@/auth/index'

/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
*/
export default {
  components: {
    Layout
  },
  data() {
    return {
      isRouteLoaded: false
    }
  },
  watch: {
    $route: {
      handler (val) {
        // console.log(val)
        if (val && val.name && !this.isRouteLoaded) {
          this.isRouteLoaded = true
          this.runRouteChange()
          // console.log(val)
        }
      }
    }
  },
  mounted() {
    if (auth.isLoggedIn()) {
      this.getFrontendSetting()
    }
  },
  created() {
    const lang = localStorage.selectedLanguage ? localStorage.selectedLanguage : 'en'

    this.$lang.setLang(this.$route.params.lang || lang)

    localStorage.setItem('selectedLanguage', this.$route.params.lang || lang)

    const savedLocationsLoad = localStorage.savedLocations ? JSON.parse(localStorage.savedLocations) : []

    this.savedLocationsSave(savedLocationsLoad)

    this.checkStaticHash()
  },
  methods: {
    ...mapActions('app', [
      'defaultFrontend',
      'savedLocationsSave'
    ]),
    runRouteChange () {
      this.$router.beforeEach((to, from, next) => {
        if (to?.name && to.name !== 'error') {
          const savedLocations = localStorage.savedLocations ? JSON.parse(localStorage.savedLocations) : []
          const savedLocationsPaths = savedLocations.map((x) => x.fullPath)

          this.savedLocationsSave(savedLocations)

          const isFromEdit = from.fullPath.split('/').includes('edit')
          const isToEdit = to.fullPath.split('/').includes('edit')
          const isFromInSaved = savedLocationsPaths.includes(from.fullPath) ? savedLocationsPaths.indexOf(from.fullPath) : -1
          const isToInSaved = savedLocationsPaths.includes(to.fullPath) ? savedLocationsPaths.indexOf(from.fullPath) : -1

          if (isFromEdit) this.handleEditCreate(savedLocations, from, isFromInSaved)

          if (isToEdit) this.handleEditCreate(savedLocations, to, isToInSaved)

          // eslint-disable-next-line callback-return
          next()
        }
        this.checkStaticHash()
      })
    },
    iconHandler(type, subType) {
      switch (type) {
      case 'processes':
        return 'mdi-console-network'
      case 'cron':
        return 'mdi-briefcase-clock-outline'
      case 'rest':
        return 'mdi-web'
      case 'credentials':
        return 'mdi-lock'
      case 'templates':
        return 'mdi-text-box-outline'
      case 'translations':
        return 'mdi-text'
      case 'validations':
        return 'mdi-magnify-scan'
      case 'plugins':
        return 'mdi-puzzle-outline'
      case 'modules':
        return 'mdi-briefcase-edit-outline'
      case 'local':
        return 'mdi-briefcase-edit-outline'
      case 'users':
        return 'mdi-account'
      case 'roles':
        return 'mdi-account-group-outline'
      case 'history':
        return 'mdi-history'
      case 'settings':
        if (subType === 'FRONTEND') return 'mdi-flip-to-front'
        if (subType === 'INSTANCE') return 'mdi-flip-to-back'
        if (subType === 'PROCESS') return 'mdi-flip-vertical'
        if (subType === 'PLUGIN') return 'mdi-puzzle-star-outline'

        return 'mdi-cog'
      case 'libraries':
        return 'mdi-library-outline'
      default:
        return 'mdi-home'
      }
    },
    handleEditCreate(savedLocations, editObject, isInSavedIndex) {
      let savedLocationsInner = savedLocations

      const resourcesTypes = {
        translations: 'translation',
        modules: 'module',
        local: 'module',
        processes: 'process',
        credentials: 'process-credential',
        templates: 'text-template',
        cron: 'trigger-cron',
        rest: 'trigger-rest',
        validations: 'validation-rule',
        plugins: 'plugin',
        settings: 'setting',
        users: 'user',
        roles: 'role',
        history: 'history',
        hub: 'hub/module',
        libraries: 'library'
      }

      const isNestedRoute = (routeName) => {
        const nestedRoutes = ['triggers', 'permissions', 'templates', 'modules', 'hub']

        return nestedRoutes.includes(routeName)
      }

      if (isInSavedIndex !== -1) {
        savedLocationsInner.sort((x,y) => { return x === savedLocationsInner[isInSavedIndex] ? -1 : y === savedLocationsInner[isInSavedIndex] ? 1 : 0 })
        localStorage.savedLocations = JSON.stringify(savedLocationsInner)
        this.savedLocationsSave(savedLocationsInner)
      } else {
        let axiosUrl = `/api/${resourcesTypes[isNestedRoute(editObject.fullPath.split('/')[2]) ? editObject.fullPath.split('/')[3] : editObject.fullPath.split('/')[2]]}/${editObject.params.id}`

        if (editObject.query) {
          const queries = Object.keys(editObject.query).map((key) => `${key}=${editObject.query[key]}`)

          axiosUrl += `?${queries.join('&')}`
        }

        if (axiosUrl) {
          axios.get(axiosUrl)
            .then((res) => {
              if (res.status === 404) {
                return
              }
              savedLocationsInner.unshift({
                fullPath: editObject.fullPath,
                name: res.data?.data?.name || res.data?.data?.resourceName,
                icon: this.iconHandler(isNestedRoute(editObject.fullPath.split('/')[2]) ? editObject.fullPath.split('/')[3] : editObject.fullPath.split('/')[2], res.data?.data?.type)
              })
              savedLocationsInner = savedLocationsInner.filter((value, index, self) =>
                index === self.findIndex((t) => (
                  t.fullPath === value.fullPath
                ))
              )
              localStorage.savedLocations = JSON.stringify(savedLocationsInner.slice(0, 15))
              this.savedLocationsSave(savedLocationsInner.slice(0, 15))
            })
        }
      }
    },
    getFrontendSetting() {
      getSettings({ type: 'FRONTEND' })
        .then((res) => {

          const defaultFe = res?.data?.data?.items.find((x) => x.name === 'DEFAULT FRONTEND') || false

          if (defaultFe) this.defaultFrontend(defaultFe.values)
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
    checkStaticHash() {
      fetch(process.env.NODE_ENV === 'development' ? '/version.txt' : '/static/version.txt')
        .then((r) => r.json())
        .then((staticHash) => {
          if (staticHash) {
            if (!localStorage.frontendVersion) {
              localStorage.frontendVersion = staticHash
              window.location.reload(true)
            } else if (Number(localStorage.frontendVersion) !== staticHash) {
              localStorage.frontendVersion = staticHash
              window.location.reload(true)
            }
          }
        })
        .catch(() => {
          if (localStorage.frontendVersion !== 'null') {
            localStorage.frontendVersion = 'null'
            window.location.reload(true)
          } else {
            localStorage.frontendVersion = 'null'
          }
        })
      fetch(process.env.NODE_ENV === 'development' ? '/buildtime.txt' : '/static/buildtime.txt')
        .then((r) => r.text())
        .then((buildtime) => {
          const year = buildtime.substring(0, 4)
          const month = buildtime.substring(4, 6)
          const day = buildtime.substring(6, 8)
          const hour = buildtime.substring(9, 11)
          const minute = buildtime.substring(11, 13)
          const second = buildtime.substring(13, 15)

          const buildtimeDate = new Date(year, Number(month) - 1, day, hour, minute, second).toISOString()

          if (buildtimeDate) {
            if (!localStorage.frontendBuildtime) {
              localStorage.frontendBuildtime = buildtimeDate
              window.location.reload(true)
            } else if (localStorage.frontendBuildtime !== buildtimeDate) {
              localStorage.frontendBuildtime = buildtimeDate
              window.location.reload(true)
            }
          }
        })
        .catch(() => {
          if (localStorage.frontendVersion !== 'null') {
            localStorage.frontendVersion = 'null'
            window.location.reload(true)
          } else {
            localStorage.frontendVersion = 'null'
          }
        })
      fetch('/api/actuator/info')
        .then((r) => r.json())
        .then((result) => {
          if (result && result.git) {
            if (!localStorage.backendVersion) {
              localStorage.backendVersion = JSON.stringify(result.git)
              window.location.reload(true)
            } else if (localStorage.backendVersion !== JSON.stringify(result.git)) {
              localStorage.backendVersion = JSON.stringify(result.git)
              window.location.reload(true)
            }
          }
        })
        .catch(() => {
          console.log('Err')
        })
    }
  },
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: 'stylesheet', href }))
    ]
  }
}
</script>
<style lang="scss">
.button-default-width {
  width: 100px !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--v-secondary-base);
  border-radius: 4px;
}

.color-primary {
  color: var(--v-primary-base);
}

.color-secondary {
  color: var(--v-secondary-base);
}

.color-error {
  color: var(--v-error-base);
}

.color-black {
  color: black !important;
}

.color-accent {
  color: var(--v-accent-base);
}

.color-accent-text {
  color: var(--v-accentText-base) !important;
}

.color-success {
  color: var(--v-success-base);
}

.color-customStepLogSelect {
  color: var(--v-customStepLogSelect-base);
}

.clickable-simple {
  cursor: pointer;
}

.underline-simple {
  text-decoration: underline;
}

.one-page-height {
  max-height: 82vh;
}

.required-asterisk label:after {
  content: ' *';
  color: var(--v-error-base);
}

.max-width {
  width: 100% !important;
}

.mark-class {
  border: var(--v-customDrawerBg-base) 1px solid;
  border-radius: 8px;
  max-height: 130px;
  min-height: 130px;
  overflow-y: auto;
  background-color: var(--v-customBg-base)
}

table > tbody > tr > td {
  border-bottom: thin solid var(--v-tableBorderLinesColor-base) !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: thin solid var(--v-tableBorderLinesColor-base) !important;
}

.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: thin solid var(--v-tableBorderLinesColor-base) !important;
}

.v-text-field--outlined fieldset {
  border-color: var(--v-customInputBorderColor-base) !important;
}

.theme--dark.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: var(--v-customInputBorderColor-base) !important;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: var(--v-customInputBorderColor-base) !important;
}

.theme--dark.v-expansion-panels .v-expansion-panel--active {
  background-color: var(--v-customDrawerBg-base) !important;
  border: 1px solid var(--v-customInputBorderColor-base) !important;
}

.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: var(--v-customDrawerBg-base) !important;
  border: 1px solid var(--v-customInputBorderColor-base) !important;
}

.theme--light.v-expansion-panels .v-expansion-panel--active {
  background-color: var(--v-customDrawerBg-base) !important;
  border: 1px solid var(--v-customInputBorderColor-base) !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: var(--v-customDrawerBg-base) !important;
  border: 1px solid var(--v-customInputBorderColor-base) !important;
}
</style>
