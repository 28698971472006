<template>
  <div class="basic">
    <v-btn
      icon
      small
      text
      :color="$vuetify.theme.dark ? 'primary' : 'secondary'"
      class="up"
      :disabled="isException ? !canGoUp : (stepLength === 1 || localId.slice(-1) === '1' || !canEdit)"
      @click="$emit('move', 'up')"
    >
      <v-icon small>mdi-arrow-up</v-icon>
    </v-btn>
    <v-btn
      icon
      small
      text
      :color="$vuetify.theme.dark ? 'primary' : 'secondary'"
      class="down"
      :disabled="isException ? !canGoDown : (stepLength === 1 || localId.slice(-1) === String(stepLength) || !canEdit)"
      @click="$emit('move', 'down')"
    >
      <v-icon small>mdi-arrow-down</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    stepLength: {
      type: Number,
      default: () => {
        return 0
      }
    },
    stepDepth: {
      type: Number,
      default: () => {
        return 0
      }
    },
    localId: {
      type: String,
      default: () => {
        return ''
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    isException: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    canGoUp: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    canGoDown: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.basic {
  position: relative;
  height: 54px;
  width: 45px;
}

.left {
  position: absolute;
  top: 14px;
  left: 0;
}

.right {
  position: absolute;
  top: 14px;
  right: 0;
}

.up {
  position: absolute;
  top: 0;
  left: 16px;
}

.down {
  position: absolute;
  bottom: 0;
  left: 16px;
}
</style>
