/* eslint-disable */
import axios from 'axios'
import qs from 'qs'
let domain = ''
export const getDomain = () => {
  return domain
}
export const setDomain = ($domain) => {
  domain = $domain
}
export const request = (method, url, body, queryParameters, form, config) => {
  method = method.toLowerCase()
  let keys = Object.keys(queryParameters)
  let queryUrl = url
  if (keys.length > 0) {
    queryUrl = url + '?' + qs.stringify(queryParameters)
  }
  // let queryUrl = url+(keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
  if (body) {
    return axios[method](queryUrl, body, config)
  } else if (method === 'get') {
    return axios[method](queryUrl, {
      params: form
    }, config)
  } else {
    return axios[method](queryUrl, qs.stringify(form), config)
  }
}
/*==========================================================
 *                    
 ==========================================================*/
/**
 * captchaCreate
 * request: captchaCreateUsingGET
 * url: captchaCreateUsingGETURL
 * method: captchaCreateUsingGET_TYPE
 * raw_url: captchaCreateUsingGET_RAW_URL
 */
export const captchaCreateUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/captcha/create'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const captchaCreateUsingGET_RAW_URL = function() {
  return '/api/captcha/create'
}
export const captchaCreateUsingGET_TYPE = function() {
  return 'get'
}
export const captchaCreateUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/captcha/create'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getEvents
 * request: getEventsUsingGET
 * url: getEventsUsingGETURL
 * method: getEventsUsingGET_TYPE
 * raw_url: getEventsUsingGET_RAW_URL
 * @param dateFrom - 
 * @param dateTill - 
 * @param page - 
 * @param processId - 
 * @param size - 
 * @param status - 
 * @param triggerId - 
 * @param type - 
 */
export const getEventsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/event'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['triggerId'] !== undefined) {
    queryParameters['triggerId'] = parameters['triggerId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEventsUsingGET_RAW_URL = function() {
  return '/api/event'
}
export const getEventsUsingGET_TYPE = function() {
  return 'get'
}
export const getEventsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/event'
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['triggerId'] !== undefined) {
    queryParameters['triggerId'] = parameters['triggerId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getMostRecentEventId
 * request: getMostRecentEventIdUsingGET
 * url: getMostRecentEventIdUsingGETURL
 * method: getMostRecentEventIdUsingGET_TYPE
 * raw_url: getMostRecentEventIdUsingGET_RAW_URL
 * @param processId - processId
 */
export const getMostRecentEventIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/event/most-recent/{processId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{processId}', `${parameters['processId']}`)
  if (parameters['processId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: processId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getMostRecentEventIdUsingGET_RAW_URL = function() {
  return '/api/event/most-recent/{processId}'
}
export const getMostRecentEventIdUsingGET_TYPE = function() {
  return 'get'
}
export const getMostRecentEventIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/event/most-recent/{processId}'
  path = path.replace('{processId}', `${parameters['processId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getEventById
 * request: getEventByIdUsingGET
 * url: getEventByIdUsingGETURL
 * method: getEventByIdUsingGET_TYPE
 * raw_url: getEventByIdUsingGET_RAW_URL
 * @param id - id
 */
export const getEventByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/event/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEventByIdUsingGET_RAW_URL = function() {
  return '/api/event/{id}'
}
export const getEventByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getEventByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/event/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * forgotPassword
 * request: forgotPasswordUsingPOST
 * url: forgotPasswordUsingPOSTURL
 * method: forgotPasswordUsingPOST_TYPE
 * raw_url: forgotPasswordUsingPOST_RAW_URL
 * @param request - request
 */
export const forgotPasswordUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/forgot-password'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const forgotPasswordUsingPOST_RAW_URL = function() {
  return '/api/forgot-password'
}
export const forgotPasswordUsingPOST_TYPE = function() {
  return 'post'
}
export const forgotPasswordUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/forgot-password'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * cronTriggersGdprReport
 * request: cronTriggersGdprReportUsingGET
 * url: cronTriggersGdprReportUsingGETURL
 * method: cronTriggersGdprReportUsingGET_TYPE
 * raw_url: cronTriggersGdprReportUsingGET_RAW_URL
 */
export const cronTriggersGdprReportUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/gdpr/cron-trigger'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cronTriggersGdprReportUsingGET_RAW_URL = function() {
  return '/api/gdpr/cron-trigger'
}
export const cronTriggersGdprReportUsingGET_TYPE = function() {
  return 'get'
}
export const cronTriggersGdprReportUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/gdpr/cron-trigger'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * librariesGdprReport
 * request: librariesGdprReportUsingGET
 * url: librariesGdprReportUsingGETURL
 * method: librariesGdprReportUsingGET_TYPE
 * raw_url: librariesGdprReportUsingGET_RAW_URL
 */
export const librariesGdprReportUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/gdpr/libraries'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const librariesGdprReportUsingGET_RAW_URL = function() {
  return '/api/gdpr/libraries'
}
export const librariesGdprReportUsingGET_TYPE = function() {
  return 'get'
}
export const librariesGdprReportUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/gdpr/libraries'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * messagingTriggersGdprReport
 * request: messagingTriggersGdprReportUsingGET
 * url: messagingTriggersGdprReportUsingGETURL
 * method: messagingTriggersGdprReportUsingGET_TYPE
 * raw_url: messagingTriggersGdprReportUsingGET_RAW_URL
 */
export const messagingTriggersGdprReportUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/gdpr/messaging-trigger'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const messagingTriggersGdprReportUsingGET_RAW_URL = function() {
  return '/api/gdpr/messaging-trigger'
}
export const messagingTriggersGdprReportUsingGET_TYPE = function() {
  return 'get'
}
export const messagingTriggersGdprReportUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/gdpr/messaging-trigger'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * modulesGdprReport
 * request: modulesGdprReportUsingGET
 * url: modulesGdprReportUsingGETURL
 * method: modulesGdprReportUsingGET_TYPE
 * raw_url: modulesGdprReportUsingGET_RAW_URL
 */
export const modulesGdprReportUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/gdpr/modules'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const modulesGdprReportUsingGET_RAW_URL = function() {
  return '/api/gdpr/modules'
}
export const modulesGdprReportUsingGET_TYPE = function() {
  return 'get'
}
export const modulesGdprReportUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/gdpr/modules'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * processCredentialsGdprReport
 * request: processCredentialsGdprReportUsingGET
 * url: processCredentialsGdprReportUsingGETURL
 * method: processCredentialsGdprReportUsingGET_TYPE
 * raw_url: processCredentialsGdprReportUsingGET_RAW_URL
 */
export const processCredentialsGdprReportUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/gdpr/process-credentials'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const processCredentialsGdprReportUsingGET_RAW_URL = function() {
  return '/api/gdpr/process-credentials'
}
export const processCredentialsGdprReportUsingGET_TYPE = function() {
  return 'get'
}
export const processCredentialsGdprReportUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/gdpr/process-credentials'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * processesGdprReport
 * request: processesGdprReportUsingGET
 * url: processesGdprReportUsingGETURL
 * method: processesGdprReportUsingGET_TYPE
 * raw_url: processesGdprReportUsingGET_RAW_URL
 */
export const processesGdprReportUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/gdpr/processes'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const processesGdprReportUsingGET_RAW_URL = function() {
  return '/api/gdpr/processes'
}
export const processesGdprReportUsingGET_TYPE = function() {
  return 'get'
}
export const processesGdprReportUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/gdpr/processes'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * restTriggersGdprReport
 * request: restTriggersGdprReportUsingGET
 * url: restTriggersGdprReportUsingGETURL
 * method: restTriggersGdprReportUsingGET_TYPE
 * raw_url: restTriggersGdprReportUsingGET_RAW_URL
 */
export const restTriggersGdprReportUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/gdpr/rest-trigger'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const restTriggersGdprReportUsingGET_RAW_URL = function() {
  return '/api/gdpr/rest-trigger'
}
export const restTriggersGdprReportUsingGET_TYPE = function() {
  return 'get'
}
export const restTriggersGdprReportUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/gdpr/rest-trigger'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getHistories
 * request: getHistoriesUsingGET
 * url: getHistoriesUsingGETURL
 * method: getHistoriesUsingGET_TYPE
 * raw_url: getHistoriesUsingGET_RAW_URL
 * @param page - 
 * @param resourceId - 
 * @param resourceType - 
 * @param size - 
 */
export const getHistoriesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/history'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['resourceId'] !== undefined) {
    queryParameters['resourceId'] = parameters['resourceId']
  }
  if (parameters['resourceType'] !== undefined) {
    queryParameters['resourceType'] = parameters['resourceType']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getHistoriesUsingGET_RAW_URL = function() {
  return '/api/history'
}
export const getHistoriesUsingGET_TYPE = function() {
  return 'get'
}
export const getHistoriesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/history'
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['resourceId'] !== undefined) {
    queryParameters['resourceId'] = parameters['resourceId']
  }
  if (parameters['resourceType'] !== undefined) {
    queryParameters['resourceType'] = parameters['resourceType']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getDeleteHistories
 * request: getDeleteHistoriesUsingGET
 * url: getDeleteHistoriesUsingGETURL
 * method: getDeleteHistoriesUsingGET_TYPE
 * raw_url: getDeleteHistoriesUsingGET_RAW_URL
 * @param dateFrom - 
 * @param dateTill - 
 * @param page - 
 * @param resourceName - 
 * @param resourceType - 
 * @param size - 
 */
export const getDeleteHistoriesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/history/deletion'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['resourceName'] !== undefined) {
    queryParameters['resourceName'] = parameters['resourceName']
  }
  if (parameters['resourceType'] !== undefined) {
    queryParameters['resourceType'] = parameters['resourceType']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDeleteHistoriesUsingGET_RAW_URL = function() {
  return '/api/history/deletion'
}
export const getDeleteHistoriesUsingGET_TYPE = function() {
  return 'get'
}
export const getDeleteHistoriesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/history/deletion'
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['resourceName'] !== undefined) {
    queryParameters['resourceName'] = parameters['resourceName']
  }
  if (parameters['resourceType'] !== undefined) {
    queryParameters['resourceType'] = parameters['resourceType']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getHistoryById
 * request: getHistoryByIdUsingGET
 * url: getHistoryByIdUsingGETURL
 * method: getHistoryByIdUsingGET_TYPE
 * raw_url: getHistoryByIdUsingGET_RAW_URL
 * @param id - id
 */
export const getHistoryByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/history/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getHistoryByIdUsingGET_RAW_URL = function() {
  return '/api/history/{id}'
}
export const getHistoryByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getHistoryByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/history/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * hubModuleDownload
 * request: hubModuleDownloadUsingGET
 * url: hubModuleDownloadUsingGETURL
 * method: hubModuleDownloadUsingGET_TYPE
 * raw_url: hubModuleDownloadUsingGET_RAW_URL
 * @param credentialId - credentialId
 * @param remoteModuleId - remoteModuleId
 */
export const hubModuleDownloadUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/hub/download-module/{remoteModuleId}/credential/{credentialId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{credentialId}', `${parameters['credentialId']}`)
  if (parameters['credentialId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: credentialId'))
  }
  path = path.replace('{remoteModuleId}', `${parameters['remoteModuleId']}`)
  if (parameters['remoteModuleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: remoteModuleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const hubModuleDownloadUsingGET_RAW_URL = function() {
  return '/api/hub/download-module/{remoteModuleId}/credential/{credentialId}'
}
export const hubModuleDownloadUsingGET_TYPE = function() {
  return 'get'
}
export const hubModuleDownloadUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/hub/download-module/{remoteModuleId}/credential/{credentialId}'
  path = path.replace('{credentialId}', `${parameters['credentialId']}`)
  path = path.replace('{remoteModuleId}', `${parameters['remoteModuleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * hubGetModule
 * request: hubGetModuleUsingGET
 * url: hubGetModuleUsingGETURL
 * method: hubGetModuleUsingGET_TYPE
 * raw_url: hubGetModuleUsingGET_RAW_URL
 * @param credentialId - 
 * @param moduleId - moduleId
 */
export const hubGetModuleUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/hub/module/{moduleId}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['credentialId'] !== undefined) {
    queryParameters['credentialId'] = parameters['credentialId']
  }
  if (parameters['credentialId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: credentialId'))
  }
  path = path.replace('{moduleId}', `${parameters['moduleId']}`)
  if (parameters['moduleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: moduleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const hubGetModuleUsingGET_RAW_URL = function() {
  return '/api/hub/module/{moduleId}'
}
export const hubGetModuleUsingGET_TYPE = function() {
  return 'get'
}
export const hubGetModuleUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/hub/module/{moduleId}'
  if (parameters['credentialId'] !== undefined) {
    queryParameters['credentialId'] = parameters['credentialId']
  }
  path = path.replace('{moduleId}', `${parameters['moduleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * hubModuleSearch
 * request: hubModuleSearchUsingGET
 * url: hubModuleSearchUsingGETURL
 * method: hubModuleSearchUsingGET_TYPE
 * raw_url: hubModuleSearchUsingGET_RAW_URL
 * @param credentialId - 
 * @param name - 
 * @param page - 
 * @param size - 
 */
export const hubModuleSearchUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/hub/modules'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['credentialId'] !== undefined) {
    queryParameters['credentialId'] = parameters['credentialId']
  }
  if (parameters['credentialId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: credentialId'))
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const hubModuleSearchUsingGET_RAW_URL = function() {
  return '/api/hub/modules'
}
export const hubModuleSearchUsingGET_TYPE = function() {
  return 'get'
}
export const hubModuleSearchUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/hub/modules'
  if (parameters['credentialId'] !== undefined) {
    queryParameters['credentialId'] = parameters['credentialId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * hubReviewsSearch
 * request: hubReviewsSearchUsingGET
 * url: hubReviewsSearchUsingGETURL
 * method: hubReviewsSearchUsingGET_TYPE
 * raw_url: hubReviewsSearchUsingGET_RAW_URL
 * @param credentialId - 
 * @param page - 
 * @param remoteModuleId - 
 * @param size - 
 */
export const hubReviewsSearchUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/hub/review'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['credentialId'] !== undefined) {
    queryParameters['credentialId'] = parameters['credentialId']
  }
  if (parameters['credentialId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: credentialId'))
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['remoteModuleId'] !== undefined) {
    queryParameters['remoteModuleId'] = parameters['remoteModuleId']
  }
  if (parameters['remoteModuleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: remoteModuleId'))
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const hubReviewsSearchUsingGET_RAW_URL = function() {
  return '/api/hub/review'
}
export const hubReviewsSearchUsingGET_TYPE = function() {
  return 'get'
}
export const hubReviewsSearchUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/hub/review'
  if (parameters['credentialId'] !== undefined) {
    queryParameters['credentialId'] = parameters['credentialId']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['remoteModuleId'] !== undefined) {
    queryParameters['remoteModuleId'] = parameters['remoteModuleId']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * hubReviewCreate
 * request: hubReviewCreateUsingPOST
 * url: hubReviewCreateUsingPOSTURL
 * method: hubReviewCreateUsingPOST_TYPE
 * raw_url: hubReviewCreateUsingPOST_RAW_URL
 * @param request - request
 */
export const hubReviewCreateUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/hub/review'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const hubReviewCreateUsingPOST_RAW_URL = function() {
  return '/api/hub/review'
}
export const hubReviewCreateUsingPOST_TYPE = function() {
  return 'post'
}
export const hubReviewCreateUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/hub/review'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * hubModuleUpdate
 * request: hubModuleUpdateUsingGET
 * url: hubModuleUpdateUsingGETURL
 * method: hubModuleUpdateUsingGET_TYPE
 * raw_url: hubModuleUpdateUsingGET_RAW_URL
 * @param moduleId - moduleId
 */
export const hubModuleUpdateUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/hub/update-module/{moduleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{moduleId}', `${parameters['moduleId']}`)
  if (parameters['moduleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: moduleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const hubModuleUpdateUsingGET_RAW_URL = function() {
  return '/api/hub/update-module/{moduleId}'
}
export const hubModuleUpdateUsingGET_TYPE = function() {
  return 'get'
}
export const hubModuleUpdateUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/hub/update-module/{moduleId}'
  path = path.replace('{moduleId}', `${parameters['moduleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * hubUploadModule
 * request: hubUploadModuleUsingGET
 * url: hubUploadModuleUsingGETURL
 * method: hubUploadModuleUsingGET_TYPE
 * raw_url: hubUploadModuleUsingGET_RAW_URL
 * @param moduleId - moduleId
 */
export const hubUploadModuleUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/hub/upload-module/{moduleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{moduleId}', `${parameters['moduleId']}`)
  if (parameters['moduleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: moduleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const hubUploadModuleUsingGET_RAW_URL = function() {
  return '/api/hub/upload-module/{moduleId}'
}
export const hubUploadModuleUsingGET_TYPE = function() {
  return 'get'
}
export const hubUploadModuleUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/hub/upload-module/{moduleId}'
  path = path.replace('{moduleId}', `${parameters['moduleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getInstances
 * request: getInstancesUsingGET
 * url: getInstancesUsingGETURL
 * method: getInstancesUsingGET_TYPE
 * raw_url: getInstancesUsingGET_RAW_URL
 * @param name - 
 * @param orderDirection - 
 * @param orderField - 
 * @param page - 
 * @param size - 
 */
export const getInstancesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/instance'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getInstancesUsingGET_RAW_URL = function() {
  return '/api/instance'
}
export const getInstancesUsingGET_TYPE = function() {
  return 'get'
}
export const getInstancesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/instance'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getInstanceById
 * request: getInstanceByIdUsingGET
 * url: getInstanceByIdUsingGETURL
 * method: getInstanceByIdUsingGET_TYPE
 * raw_url: getInstanceByIdUsingGET_RAW_URL
 * @param id - id
 */
export const getInstanceByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/instance/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getInstanceByIdUsingGET_RAW_URL = function() {
  return '/api/instance/{id}'
}
export const getInstanceByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getInstanceByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/instance/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getInternalJobs
 * request: getInternalJobsUsingGET
 * url: getInternalJobsUsingGETURL
 * method: getInternalJobsUsingGET_TYPE
 * raw_url: getInternalJobsUsingGET_RAW_URL
 * @param instanceId - 
 * @param page - 
 * @param size - 
 * @param type - 
 */
export const getInternalJobsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/internal-job'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['instanceId'] !== undefined) {
    queryParameters['instanceId'] = parameters['instanceId']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getInternalJobsUsingGET_RAW_URL = function() {
  return '/api/internal-job'
}
export const getInternalJobsUsingGET_TYPE = function() {
  return 'get'
}
export const getInternalJobsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/internal-job'
  if (parameters['instanceId'] !== undefined) {
    queryParameters['instanceId'] = parameters['instanceId']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getInternalJobById
 * request: getInternalJobByIdUsingGET
 * url: getInternalJobByIdUsingGETURL
 * method: getInternalJobByIdUsingGET_TYPE
 * raw_url: getInternalJobByIdUsingGET_RAW_URL
 * @param id - id
 */
export const getInternalJobByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/internal-job/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getInternalJobByIdUsingGET_RAW_URL = function() {
  return '/api/internal-job/{id}'
}
export const getInternalJobByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getInternalJobByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/internal-job/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getLibraries
 * request: getLibrariesUsingGET
 * url: getLibrariesUsingGETURL
 * method: getLibrariesUsingGET_TYPE
 * raw_url: getLibrariesUsingGET_RAW_URL
 * @param name - 
 * @param orderDirection - 
 * @param orderField - 
 * @param page - 
 * @param permissionType - 
 * @param size - 
 * @param status - 
 */
export const getLibrariesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/library'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['permissionType'] !== undefined) {
    queryParameters['permissionType'] = parameters['permissionType']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getLibrariesUsingGET_RAW_URL = function() {
  return '/api/library'
}
export const getLibrariesUsingGET_TYPE = function() {
  return 'get'
}
export const getLibrariesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/library'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['permissionType'] !== undefined) {
    queryParameters['permissionType'] = parameters['permissionType']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createLibrary
 * request: createLibraryUsingPOST
 * url: createLibraryUsingPOSTURL
 * method: createLibraryUsingPOST_TYPE
 * raw_url: createLibraryUsingPOST_RAW_URL
 * @param request - request
 */
export const createLibraryUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/library'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createLibraryUsingPOST_RAW_URL = function() {
  return '/api/library'
}
export const createLibraryUsingPOST_TYPE = function() {
  return 'post'
}
export const createLibraryUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/library'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getLibraryById
 * request: getLibraryByIdUsingGET
 * url: getLibraryByIdUsingGETURL
 * method: getLibraryByIdUsingGET_TYPE
 * raw_url: getLibraryByIdUsingGET_RAW_URL
 * @param id - id
 */
export const getLibraryByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/library/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getLibraryByIdUsingGET_RAW_URL = function() {
  return '/api/library/{id}'
}
export const getLibraryByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getLibraryByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/library/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateLibrary
 * request: updateLibraryUsingPUT
 * url: updateLibraryUsingPUTURL
 * method: updateLibraryUsingPUT_TYPE
 * raw_url: updateLibraryUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateLibraryUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/library/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateLibraryUsingPUT_RAW_URL = function() {
  return '/api/library/{id}'
}
export const updateLibraryUsingPUT_TYPE = function() {
  return 'put'
}
export const updateLibraryUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/library/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteLibrary
 * request: deleteLibraryUsingDELETE
 * url: deleteLibraryUsingDELETEURL
 * method: deleteLibraryUsingDELETE_TYPE
 * raw_url: deleteLibraryUsingDELETE_RAW_URL
 * @param force - 
 * @param id - id
 */
export const deleteLibraryUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/library/{id}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteLibraryUsingDELETE_RAW_URL = function() {
  return '/api/library/{id}'
}
export const deleteLibraryUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteLibraryUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/library/{id}'
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * disableLibrary
 * request: disableLibraryUsingGET
 * url: disableLibraryUsingGETURL
 * method: disableLibraryUsingGET_TYPE
 * raw_url: disableLibraryUsingGET_RAW_URL
 * @param id - id
 */
export const disableLibraryUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/library/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const disableLibraryUsingGET_RAW_URL = function() {
  return '/api/library/{id}/disable'
}
export const disableLibraryUsingGET_TYPE = function() {
  return 'get'
}
export const disableLibraryUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/library/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * enableLibrary
 * request: enableLibraryUsingGET
 * url: enableLibraryUsingGETURL
 * method: enableLibraryUsingGET_TYPE
 * raw_url: enableLibraryUsingGET_RAW_URL
 * @param id - id
 */
export const enableLibraryUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/library/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const enableLibraryUsingGET_RAW_URL = function() {
  return '/api/library/{id}/enable'
}
export const enableLibraryUsingGET_TYPE = function() {
  return 'get'
}
export const enableLibraryUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/library/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateLibraryRoles
 * request: updateLibraryRolesUsingPUT
 * url: updateLibraryRolesUsingPUTURL
 * method: updateLibraryRolesUsingPUT_TYPE
 * raw_url: updateLibraryRolesUsingPUT_RAW_URL
 * @param id - id
 * @param resourceRolesRequest - resourceRolesRequest
 */
export const updateLibraryRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/library/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['resourceRolesRequest'] !== undefined) {
    body = parameters['resourceRolesRequest']
  }
  if (parameters['resourceRolesRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: resourceRolesRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateLibraryRolesUsingPUT_RAW_URL = function() {
  return '/api/library/{id}/roles'
}
export const updateLibraryRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateLibraryRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/library/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * uploadLibrary
 * request: uploadLibraryUsingPOST
 * url: uploadLibraryUsingPOSTURL
 * method: uploadLibraryUsingPOST_TYPE
 * raw_url: uploadLibraryUsingPOST_RAW_URL
 * @param file - file
 * @param id - id
 */
export const uploadLibraryUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/library/{id}/upload'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const uploadLibraryUsingPOST_RAW_URL = function() {
  return '/api/library/{id}/upload'
}
export const uploadLibraryUsingPOST_TYPE = function() {
  return 'post'
}
export const uploadLibraryUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/library/{id}/upload'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * login
 * request: loginUsingPOST
 * url: loginUsingPOSTURL
 * method: loginUsingPOST_TYPE
 * raw_url: loginUsingPOST_RAW_URL
 * @param loginRequest - loginRequest
 */
export const loginUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/login'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['loginRequest'] !== undefined) {
    body = parameters['loginRequest']
  }
  if (parameters['loginRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: loginRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const loginUsingPOST_RAW_URL = function() {
  return '/api/login'
}
export const loginUsingPOST_TYPE = function() {
  return 'post'
}
export const loginUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/login'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * logout
 * request: logoutUsingGET
 * url: logoutUsingGETURL
 * method: logoutUsingGET_TYPE
 * raw_url: logoutUsingGET_RAW_URL
 * @param creationTime - 
 * @param id - 
 * @param lastAccessedTime - 
 * @param maxInactiveInterval - 
 * @param new - 
 * @param valueNames - 
 */
export const logoutUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/logout'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['creationTime'] !== undefined) {
    queryParameters['creationTime'] = parameters['creationTime']
  }
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['lastAccessedTime'] !== undefined) {
    queryParameters['lastAccessedTime'] = parameters['lastAccessedTime']
  }
  if (parameters['maxInactiveInterval'] !== undefined) {
    queryParameters['maxInactiveInterval'] = parameters['maxInactiveInterval']
  }
  if (parameters['new'] !== undefined) {
    queryParameters['new'] = parameters['new']
  }
  if (parameters['valueNames'] !== undefined) {
    queryParameters['valueNames'] = parameters['valueNames']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const logoutUsingGET_RAW_URL = function() {
  return '/api/logout'
}
export const logoutUsingGET_TYPE = function() {
  return 'get'
}
export const logoutUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/logout'
  if (parameters['creationTime'] !== undefined) {
    queryParameters['creationTime'] = parameters['creationTime']
  }
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['lastAccessedTime'] !== undefined) {
    queryParameters['lastAccessedTime'] = parameters['lastAccessedTime']
  }
  if (parameters['maxInactiveInterval'] !== undefined) {
    queryParameters['maxInactiveInterval'] = parameters['maxInactiveInterval']
  }
  if (parameters['new'] !== undefined) {
    queryParameters['new'] = parameters['new']
  }
  if (parameters['valueNames'] !== undefined) {
    queryParameters['valueNames'] = parameters['valueNames']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getModules
 * request: getModulesUsingGET
 * url: getModulesUsingGETURL
 * method: getModulesUsingGET_TYPE
 * raw_url: getModulesUsingGET_RAW_URL
 * @param name - 
 * @param orderDirection - 
 * @param orderField - 
 * @param page - 
 * @param size - 
 */
export const getModulesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/module'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getModulesUsingGET_RAW_URL = function() {
  return '/api/module'
}
export const getModulesUsingGET_TYPE = function() {
  return 'get'
}
export const getModulesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/module'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createModule
 * request: createModuleUsingPOST
 * url: createModuleUsingPOSTURL
 * method: createModuleUsingPOST_TYPE
 * raw_url: createModuleUsingPOST_RAW_URL
 * @param request - request
 */
export const createModuleUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/module'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createModuleUsingPOST_RAW_URL = function() {
  return '/api/module'
}
export const createModuleUsingPOST_TYPE = function() {
  return 'post'
}
export const createModuleUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/module'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * importModule
 * request: importModuleUsingPOST
 * url: importModuleUsingPOSTURL
 * method: importModuleUsingPOST_TYPE
 * raw_url: importModuleUsingPOST_RAW_URL
 * @param file - JSON file with resources to import. Please follow ModuleImport model
 */
export const importModuleUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/module/import'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const importModuleUsingPOST_RAW_URL = function() {
  return '/api/module/import'
}
export const importModuleUsingPOST_TYPE = function() {
  return 'post'
}
export const importModuleUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/module/import'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * validate
 * request: validateUsingPOST
 * url: validateUsingPOSTURL
 * method: validateUsingPOST_TYPE
 * raw_url: validateUsingPOST_RAW_URL
 * @param file - JSON file with resources to import. Please follow ModuleImport model
 */
export const validateUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/module/validate'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const validateUsingPOST_RAW_URL = function() {
  return '/api/module/validate'
}
export const validateUsingPOST_TYPE = function() {
  return 'post'
}
export const validateUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/module/validate'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getModuleById
 * request: getModuleByIdUsingGET
 * url: getModuleByIdUsingGETURL
 * method: getModuleByIdUsingGET_TYPE
 * raw_url: getModuleByIdUsingGET_RAW_URL
 * @param id - id
 */
export const getModuleByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/module/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getModuleByIdUsingGET_RAW_URL = function() {
  return '/api/module/{id}'
}
export const getModuleByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getModuleByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/module/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateModule
 * request: updateModuleUsingPUT
 * url: updateModuleUsingPUTURL
 * method: updateModuleUsingPUT_TYPE
 * raw_url: updateModuleUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateModuleUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/module/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateModuleUsingPUT_RAW_URL = function() {
  return '/api/module/{id}'
}
export const updateModuleUsingPUT_TYPE = function() {
  return 'put'
}
export const updateModuleUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/module/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteModule
 * request: deleteModuleUsingDELETE
 * url: deleteModuleUsingDELETEURL
 * method: deleteModuleUsingDELETE_TYPE
 * raw_url: deleteModuleUsingDELETE_RAW_URL
 * @param force - 
 * @param id - id
 */
export const deleteModuleUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/module/{id}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteModuleUsingDELETE_RAW_URL = function() {
  return '/api/module/{id}'
}
export const deleteModuleUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteModuleUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/module/{id}'
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * export
 * request: exportUsingGET
 * url: exportUsingGETURL
 * method: exportUsingGET_TYPE
 * raw_url: exportUsingGET_RAW_URL
 * @param id - id
 */
export const exportUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/module/{id}/export'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const exportUsingGET_RAW_URL = function() {
  return '/api/module/{id}/export'
}
export const exportUsingGET_TYPE = function() {
  return 'get'
}
export const exportUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/module/{id}/export'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateModuleRoles
 * request: updateModuleRolesUsingPUT
 * url: updateModuleRolesUsingPUTURL
 * method: updateModuleRolesUsingPUT_TYPE
 * raw_url: updateModuleRolesUsingPUT_RAW_URL
 * @param id - id
 * @param resourceRolesRequest - resourceRolesRequest
 */
export const updateModuleRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/module/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['resourceRolesRequest'] !== undefined) {
    body = parameters['resourceRolesRequest']
  }
  if (parameters['resourceRolesRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: resourceRolesRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateModuleRolesUsingPUT_RAW_URL = function() {
  return '/api/module/{id}/roles'
}
export const updateModuleRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateModuleRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/module/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * swagger
 * request: swaggerUsingGET
 * url: swaggerUsingGETURL
 * method: swaggerUsingGET_TYPE
 * raw_url: swaggerUsingGET_RAW_URL
 * @param id - id
 */
export const swaggerUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/module/{id}/swagger'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const swaggerUsingGET_RAW_URL = function() {
  return '/api/module/{id}/swagger'
}
export const swaggerUsingGET_TYPE = function() {
  return 'get'
}
export const swaggerUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/module/{id}/swagger'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updatePassword
 * request: updatePasswordUsingPUT
 * url: updatePasswordUsingPUTURL
 * method: updatePasswordUsingPUT_TYPE
 * raw_url: updatePasswordUsingPUT_RAW_URL
 * @param request - request
 */
export const updatePasswordUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/password'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updatePasswordUsingPUT_RAW_URL = function() {
  return '/api/password'
}
export const updatePasswordUsingPUT_TYPE = function() {
  return 'put'
}
export const updatePasswordUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/password'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPlugins
 * request: getPluginsUsingGET
 * url: getPluginsUsingGETURL
 * method: getPluginsUsingGET_TYPE
 * raw_url: getPluginsUsingGET_RAW_URL
 * @param name - 
 * @param orderDirection - 
 * @param orderField - 
 * @param page - 
 * @param permissionType - 
 * @param size - 
 * @param status - 
 */
export const getPluginsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/plugin'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['permissionType'] !== undefined) {
    queryParameters['permissionType'] = parameters['permissionType']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPluginsUsingGET_RAW_URL = function() {
  return '/api/plugin'
}
export const getPluginsUsingGET_TYPE = function() {
  return 'get'
}
export const getPluginsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/plugin'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['permissionType'] !== undefined) {
    queryParameters['permissionType'] = parameters['permissionType']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createPlugin
 * request: createPluginUsingPOST
 * url: createPluginUsingPOSTURL
 * method: createPluginUsingPOST_TYPE
 * raw_url: createPluginUsingPOST_RAW_URL
 * @param request - request
 */
export const createPluginUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/plugin'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createPluginUsingPOST_RAW_URL = function() {
  return '/api/plugin'
}
export const createPluginUsingPOST_TYPE = function() {
  return 'post'
}
export const createPluginUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/plugin'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPluginById
 * request: getPluginByIdUsingGET
 * url: getPluginByIdUsingGETURL
 * method: getPluginByIdUsingGET_TYPE
 * raw_url: getPluginByIdUsingGET_RAW_URL
 * @param id - id
 */
export const getPluginByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/plugin/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPluginByIdUsingGET_RAW_URL = function() {
  return '/api/plugin/{id}'
}
export const getPluginByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getPluginByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/plugin/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updatePlugin
 * request: updatePluginUsingPUT
 * url: updatePluginUsingPUTURL
 * method: updatePluginUsingPUT_TYPE
 * raw_url: updatePluginUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updatePluginUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/plugin/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updatePluginUsingPUT_RAW_URL = function() {
  return '/api/plugin/{id}'
}
export const updatePluginUsingPUT_TYPE = function() {
  return 'put'
}
export const updatePluginUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/plugin/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deletePlugin
 * request: deletePluginUsingDELETE
 * url: deletePluginUsingDELETEURL
 * method: deletePluginUsingDELETE_TYPE
 * raw_url: deletePluginUsingDELETE_RAW_URL
 * @param force - 
 * @param id - id
 */
export const deletePluginUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/plugin/{id}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deletePluginUsingDELETE_RAW_URL = function() {
  return '/api/plugin/{id}'
}
export const deletePluginUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deletePluginUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/plugin/{id}'
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * disablePlugin
 * request: disablePluginUsingGET
 * url: disablePluginUsingGETURL
 * method: disablePluginUsingGET_TYPE
 * raw_url: disablePluginUsingGET_RAW_URL
 * @param id - id
 */
export const disablePluginUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/plugin/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const disablePluginUsingGET_RAW_URL = function() {
  return '/api/plugin/{id}/disable'
}
export const disablePluginUsingGET_TYPE = function() {
  return 'get'
}
export const disablePluginUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/plugin/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * enablePlugin
 * request: enablePluginUsingGET
 * url: enablePluginUsingGETURL
 * method: enablePluginUsingGET_TYPE
 * raw_url: enablePluginUsingGET_RAW_URL
 * @param id - id
 */
export const enablePluginUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/plugin/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const enablePluginUsingGET_RAW_URL = function() {
  return '/api/plugin/{id}/enable'
}
export const enablePluginUsingGET_TYPE = function() {
  return 'get'
}
export const enablePluginUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/plugin/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updatePluginRoles
 * request: updatePluginRolesUsingPUT
 * url: updatePluginRolesUsingPUTURL
 * method: updatePluginRolesUsingPUT_TYPE
 * raw_url: updatePluginRolesUsingPUT_RAW_URL
 * @param id - id
 * @param resourceRolesRequest - resourceRolesRequest
 */
export const updatePluginRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/plugin/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['resourceRolesRequest'] !== undefined) {
    body = parameters['resourceRolesRequest']
  }
  if (parameters['resourceRolesRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: resourceRolesRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updatePluginRolesUsingPUT_RAW_URL = function() {
  return '/api/plugin/{id}/roles'
}
export const updatePluginRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updatePluginRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/plugin/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * uploadPluginJar
 * request: uploadPluginJarUsingPOST
 * url: uploadPluginJarUsingPOSTURL
 * method: uploadPluginJarUsingPOST_TYPE
 * raw_url: uploadPluginJarUsingPOST_RAW_URL
 * @param file - file
 * @param pluginId - pluginId
 */
export const uploadPluginJarUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/plugin/{pluginId}/jar'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  path = path.replace('{pluginId}', `${parameters['pluginId']}`)
  if (parameters['pluginId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pluginId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const uploadPluginJarUsingPOST_RAW_URL = function() {
  return '/api/plugin/{pluginId}/jar'
}
export const uploadPluginJarUsingPOST_TYPE = function() {
  return 'post'
}
export const uploadPluginJarUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/plugin/{pluginId}/jar'
  path = path.replace('{pluginId}', `${parameters['pluginId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getProcesses
 * request: getProcessesUsingGET
 * url: getProcessesUsingGETURL
 * method: getProcessesUsingGET_TYPE
 * raw_url: getProcessesUsingGET_RAW_URL
 * @param name - 
 * @param orderDirection - 
 * @param orderField - 
 * @param page - 
 * @param permissionType - 
 * @param size - 
 * @param status - 
 */
export const getProcessesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['permissionType'] !== undefined) {
    queryParameters['permissionType'] = parameters['permissionType']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProcessesUsingGET_RAW_URL = function() {
  return '/api/process'
}
export const getProcessesUsingGET_TYPE = function() {
  return 'get'
}
export const getProcessesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['permissionType'] !== undefined) {
    queryParameters['permissionType'] = parameters['permissionType']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createProcess
 * request: createProcessUsingPOST
 * url: createProcessUsingPOSTURL
 * method: createProcessUsingPOST_TYPE
 * raw_url: createProcessUsingPOST_RAW_URL
 * @param processRequest - processRequest
 */
export const createProcessUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['processRequest'] !== undefined) {
    body = parameters['processRequest']
  }
  if (parameters['processRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: processRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createProcessUsingPOST_RAW_URL = function() {
  return '/api/process'
}
export const createProcessUsingPOST_TYPE = function() {
  return 'post'
}
export const createProcessUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getProcessCredentials
 * request: getProcessCredentialsUsingGET
 * url: getProcessCredentialsUsingGETURL
 * method: getProcessCredentialsUsingGET_TYPE
 * raw_url: getProcessCredentialsUsingGET_RAW_URL
 * @param name - 
 * @param orderDirection - 
 * @param orderField - 
 * @param page - 
 * @param size - 
 * @param status - 
 * @param type - 
 */
export const getProcessCredentialsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProcessCredentialsUsingGET_RAW_URL = function() {
  return '/api/process-credential'
}
export const getProcessCredentialsUsingGET_TYPE = function() {
  return 'get'
}
export const getProcessCredentialsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createAwsCredential
 * request: createAwsCredentialUsingPOST
 * url: createAwsCredentialUsingPOSTURL
 * method: createAwsCredentialUsingPOST_TYPE
 * raw_url: createAwsCredentialUsingPOST_RAW_URL
 * @param request - request
 */
export const createAwsCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/aws'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createAwsCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/aws'
}
export const createAwsCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createAwsCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/aws'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateAwsCredential
 * request: updateAwsCredentialUsingPUT
 * url: updateAwsCredentialUsingPUTURL
 * method: updateAwsCredentialUsingPUT_TYPE
 * raw_url: updateAwsCredentialUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateAwsCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/aws/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateAwsCredentialUsingPUT_RAW_URL = function() {
  return '/api/process-credential/aws/{id}'
}
export const updateAwsCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateAwsCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/aws/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createFlowyCredential
 * request: createFlowyCredentialUsingPOST
 * url: createFlowyCredentialUsingPOSTURL
 * method: createFlowyCredentialUsingPOST_TYPE
 * raw_url: createFlowyCredentialUsingPOST_RAW_URL
 * @param request - request
 */
export const createFlowyCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/flowy'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createFlowyCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/flowy'
}
export const createFlowyCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createFlowyCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/flowy'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateFlowyCredential
 * request: updateFlowyCredentialUsingPUT
 * url: updateFlowyCredentialUsingPUTURL
 * method: updateFlowyCredentialUsingPUT_TYPE
 * raw_url: updateFlowyCredentialUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateFlowyCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/flowy/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateFlowyCredentialUsingPUT_RAW_URL = function() {
  return '/api/process-credential/flowy/{id}'
}
export const updateFlowyCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateFlowyCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/flowy/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createImapProcessCredential
 * request: createImapProcessCredentialUsingPOST
 * url: createImapProcessCredentialUsingPOSTURL
 * method: createImapProcessCredentialUsingPOST_TYPE
 * raw_url: createImapProcessCredentialUsingPOST_RAW_URL
 * @param request - request
 */
export const createImapProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/imap'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createImapProcessCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/imap'
}
export const createImapProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createImapProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/imap'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateImapProcessCredential
 * request: updateImapProcessCredentialUsingPUT
 * url: updateImapProcessCredentialUsingPUTURL
 * method: updateImapProcessCredentialUsingPUT_TYPE
 * raw_url: updateImapProcessCredentialUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateImapProcessCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/imap/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateImapProcessCredentialUsingPUT_RAW_URL = function() {
  return '/api/process-credential/imap/{id}'
}
export const updateImapProcessCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateImapProcessCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/imap/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createJdbcProcessCredential
 * request: createJdbcProcessCredentialUsingPOST
 * url: createJdbcProcessCredentialUsingPOSTURL
 * method: createJdbcProcessCredentialUsingPOST_TYPE
 * raw_url: createJdbcProcessCredentialUsingPOST_RAW_URL
 * @param request - request
 */
export const createJdbcProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/jdbc'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createJdbcProcessCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/jdbc'
}
export const createJdbcProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createJdbcProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/jdbc'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateJdbcProcessCredential
 * request: updateJdbcProcessCredentialUsingPUT
 * url: updateJdbcProcessCredentialUsingPUTURL
 * method: updateJdbcProcessCredentialUsingPUT_TYPE
 * raw_url: updateJdbcProcessCredentialUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateJdbcProcessCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/jdbc/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateJdbcProcessCredentialUsingPUT_RAW_URL = function() {
  return '/api/process-credential/jdbc/{id}'
}
export const updateJdbcProcessCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateJdbcProcessCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/jdbc/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * executeJdbcScript
 * request: executeJdbcScriptUsingPOST
 * url: executeJdbcScriptUsingPOSTURL
 * method: executeJdbcScriptUsingPOST_TYPE
 * raw_url: executeJdbcScriptUsingPOST_RAW_URL
 * @param id - id
 * @param request - request
 */
export const executeJdbcScriptUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/jdbc/{id}/execute-script'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const executeJdbcScriptUsingPOST_RAW_URL = function() {
  return '/api/process-credential/jdbc/{id}/execute-script'
}
export const executeJdbcScriptUsingPOST_TYPE = function() {
  return 'post'
}
export const executeJdbcScriptUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/jdbc/{id}/execute-script'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createJmsCredential
 * request: createJmsCredentialUsingPOST
 * url: createJmsCredentialUsingPOSTURL
 * method: createJmsCredentialUsingPOST_TYPE
 * raw_url: createJmsCredentialUsingPOST_RAW_URL
 * @param request - request
 */
export const createJmsCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/jms'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createJmsCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/jms'
}
export const createJmsCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createJmsCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/jms'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateJmsCredential
 * request: updateJmsCredentialUsingPUT
 * url: updateJmsCredentialUsingPUTURL
 * method: updateJmsCredentialUsingPUT_TYPE
 * raw_url: updateJmsCredentialUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateJmsCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/jms/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateJmsCredentialUsingPUT_RAW_URL = function() {
  return '/api/process-credential/jms/{id}'
}
export const updateJmsCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateJmsCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/jms/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createJwtProcessCredential
 * request: createJwtProcessCredentialUsingPOST
 * url: createJwtProcessCredentialUsingPOSTURL
 * method: createJwtProcessCredentialUsingPOST_TYPE
 * raw_url: createJwtProcessCredentialUsingPOST_RAW_URL
 * @param request - request
 */
export const createJwtProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/jwt'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createJwtProcessCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/jwt'
}
export const createJwtProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createJwtProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/jwt'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateJwtProcessCredential
 * request: updateJwtProcessCredentialUsingPUT
 * url: updateJwtProcessCredentialUsingPUTURL
 * method: updateJwtProcessCredentialUsingPUT_TYPE
 * raw_url: updateJwtProcessCredentialUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateJwtProcessCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/jwt/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateJwtProcessCredentialUsingPUT_RAW_URL = function() {
  return '/api/process-credential/jwt/{id}'
}
export const updateJwtProcessCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateJwtProcessCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/jwt/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createKafkaCredential
 * request: createKafkaCredentialUsingPOST
 * url: createKafkaCredentialUsingPOSTURL
 * method: createKafkaCredentialUsingPOST_TYPE
 * raw_url: createKafkaCredentialUsingPOST_RAW_URL
 * @param request - request
 */
export const createKafkaCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/kafka'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createKafkaCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/kafka'
}
export const createKafkaCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createKafkaCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/kafka'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateKafkaCredential
 * request: updateKafkaCredentialUsingPUT
 * url: updateKafkaCredentialUsingPUTURL
 * method: updateKafkaCredentialUsingPUT_TYPE
 * raw_url: updateKafkaCredentialUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateKafkaCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/kafka/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateKafkaCredentialUsingPUT_RAW_URL = function() {
  return '/api/process-credential/kafka/{id}'
}
export const updateKafkaCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateKafkaCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/kafka/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createMongodbProcessCredential
 * request: createMongodbProcessCredentialUsingPOST
 * url: createMongodbProcessCredentialUsingPOSTURL
 * method: createMongodbProcessCredentialUsingPOST_TYPE
 * raw_url: createMongodbProcessCredentialUsingPOST_RAW_URL
 * @param request - request
 */
export const createMongodbProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/mongodb'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createMongodbProcessCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/mongodb'
}
export const createMongodbProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createMongodbProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/mongodb'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateMongodbProcessCredential
 * request: updateMongodbProcessCredentialUsingPUT
 * url: updateMongodbProcessCredentialUsingPUTURL
 * method: updateMongodbProcessCredentialUsingPUT_TYPE
 * raw_url: updateMongodbProcessCredentialUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateMongodbProcessCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/mongodb/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateMongodbProcessCredentialUsingPUT_RAW_URL = function() {
  return '/api/process-credential/mongodb/{id}'
}
export const updateMongodbProcessCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateMongodbProcessCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/mongodb/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createPaymentSenseConnectECredential
 * request: createPaymentSenseConnectECredentialUsingPOST
 * url: createPaymentSenseConnectECredentialUsingPOSTURL
 * method: createPaymentSenseConnectECredentialUsingPOST_TYPE
 * raw_url: createPaymentSenseConnectECredentialUsingPOST_RAW_URL
 * @param request - request
 */
export const createPaymentSenseConnectECredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/payment-sense-connect-e'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createPaymentSenseConnectECredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/payment-sense-connect-e'
}
export const createPaymentSenseConnectECredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createPaymentSenseConnectECredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/payment-sense-connect-e'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updatePaymentSenseConnectECredential
 * request: updatePaymentSenseConnectECredentialUsingPUT
 * url: updatePaymentSenseConnectECredentialUsingPUTURL
 * method: updatePaymentSenseConnectECredentialUsingPUT_TYPE
 * raw_url: updatePaymentSenseConnectECredentialUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updatePaymentSenseConnectECredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/payment-sense-connect-e/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updatePaymentSenseConnectECredentialUsingPUT_RAW_URL = function() {
  return '/api/process-credential/payment-sense-connect-e/{id}'
}
export const updatePaymentSenseConnectECredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updatePaymentSenseConnectECredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/payment-sense-connect-e/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createPaymentSensePacCredential
 * request: createPaymentSensePacCredentialUsingPOST
 * url: createPaymentSensePacCredentialUsingPOSTURL
 * method: createPaymentSensePacCredentialUsingPOST_TYPE
 * raw_url: createPaymentSensePacCredentialUsingPOST_RAW_URL
 * @param request - request
 */
export const createPaymentSensePacCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/payment-sense-pac'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createPaymentSensePacCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/payment-sense-pac'
}
export const createPaymentSensePacCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createPaymentSensePacCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/payment-sense-pac'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updatePaymentSensePacCredential
 * request: updatePaymentSensePacCredentialUsingPUT
 * url: updatePaymentSensePacCredentialUsingPUTURL
 * method: updatePaymentSensePacCredentialUsingPUT_TYPE
 * raw_url: updatePaymentSensePacCredentialUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updatePaymentSensePacCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/payment-sense-pac/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updatePaymentSensePacCredentialUsingPUT_RAW_URL = function() {
  return '/api/process-credential/payment-sense-pac/{id}'
}
export const updatePaymentSensePacCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updatePaymentSensePacCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/payment-sense-pac/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createPluginCredential
 * request: createPluginCredentialUsingPOST
 * url: createPluginCredentialUsingPOSTURL
 * method: createPluginCredentialUsingPOST_TYPE
 * raw_url: createPluginCredentialUsingPOST_RAW_URL
 * @param request - request
 */
export const createPluginCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/plugin'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createPluginCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/plugin'
}
export const createPluginCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createPluginCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/plugin'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updatePluginCredential
 * request: updatePluginCredentialUsingPUT
 * url: updatePluginCredentialUsingPUTURL
 * method: updatePluginCredentialUsingPUT_TYPE
 * raw_url: updatePluginCredentialUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updatePluginCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/plugin/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updatePluginCredentialUsingPUT_RAW_URL = function() {
  return '/api/process-credential/plugin/{id}'
}
export const updatePluginCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updatePluginCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/plugin/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createRabbitmqCredential
 * request: createRabbitmqCredentialUsingPOST
 * url: createRabbitmqCredentialUsingPOSTURL
 * method: createRabbitmqCredentialUsingPOST_TYPE
 * raw_url: createRabbitmqCredentialUsingPOST_RAW_URL
 * @param request - request
 */
export const createRabbitmqCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/rabbitmq'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createRabbitmqCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/rabbitmq'
}
export const createRabbitmqCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createRabbitmqCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/rabbitmq'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateRabbitmqCredential
 * request: updateRabbitmqCredentialUsingPUT
 * url: updateRabbitmqCredentialUsingPUTURL
 * method: updateRabbitmqCredentialUsingPUT_TYPE
 * raw_url: updateRabbitmqCredentialUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateRabbitmqCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/rabbitmq/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateRabbitmqCredentialUsingPUT_RAW_URL = function() {
  return '/api/process-credential/rabbitmq/{id}'
}
export const updateRabbitmqCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateRabbitmqCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/rabbitmq/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createRestCredential
 * request: createRestCredentialUsingPOST
 * url: createRestCredentialUsingPOSTURL
 * method: createRestCredentialUsingPOST_TYPE
 * raw_url: createRestCredentialUsingPOST_RAW_URL
 * @param request - request
 */
export const createRestCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/rest'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createRestCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/rest'
}
export const createRestCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createRestCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/rest'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateRestCredential
 * request: updateRestCredentialUsingPUT
 * url: updateRestCredentialUsingPUTURL
 * method: updateRestCredentialUsingPUT_TYPE
 * raw_url: updateRestCredentialUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateRestCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/rest/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateRestCredentialUsingPUT_RAW_URL = function() {
  return '/api/process-credential/rest/{id}'
}
export const updateRestCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateRestCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/rest/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createScriptCredential
 * request: createScriptCredentialUsingPOST
 * url: createScriptCredentialUsingPOSTURL
 * method: createScriptCredentialUsingPOST_TYPE
 * raw_url: createScriptCredentialUsingPOST_RAW_URL
 * @param request - request
 */
export const createScriptCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/script'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createScriptCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/script'
}
export const createScriptCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createScriptCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/script'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateScriptCredential
 * request: updateScriptCredentialUsingPUT
 * url: updateScriptCredentialUsingPUTURL
 * method: updateScriptCredentialUsingPUT_TYPE
 * raw_url: updateScriptCredentialUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateScriptCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/script/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateScriptCredentialUsingPUT_RAW_URL = function() {
  return '/api/process-credential/script/{id}'
}
export const updateScriptCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateScriptCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/script/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createSecurityCredential
 * request: createSecurityCredentialUsingPOST
 * url: createSecurityCredentialUsingPOSTURL
 * method: createSecurityCredentialUsingPOST_TYPE
 * raw_url: createSecurityCredentialUsingPOST_RAW_URL
 * @param request - request
 */
export const createSecurityCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/security'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createSecurityCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/security'
}
export const createSecurityCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createSecurityCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/security'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateSecurityCredential
 * request: updateSecurityCredentialUsingPUT
 * url: updateSecurityCredentialUsingPUTURL
 * method: updateSecurityCredentialUsingPUT_TYPE
 * raw_url: updateSecurityCredentialUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateSecurityCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/security/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateSecurityCredentialUsingPUT_RAW_URL = function() {
  return '/api/process-credential/security/{id}'
}
export const updateSecurityCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateSecurityCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/security/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createSlackProcessCredential
 * request: createSlackProcessCredentialUsingPOST
 * url: createSlackProcessCredentialUsingPOSTURL
 * method: createSlackProcessCredentialUsingPOST_TYPE
 * raw_url: createSlackProcessCredentialUsingPOST_RAW_URL
 * @param request - request
 */
export const createSlackProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/slack'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createSlackProcessCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/slack'
}
export const createSlackProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createSlackProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/slack'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateSlackProcessCredential
 * request: updateSlackProcessCredentialUsingPUT
 * url: updateSlackProcessCredentialUsingPUTURL
 * method: updateSlackProcessCredentialUsingPUT_TYPE
 * raw_url: updateSlackProcessCredentialUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateSlackProcessCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/slack/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateSlackProcessCredentialUsingPUT_RAW_URL = function() {
  return '/api/process-credential/slack/{id}'
}
export const updateSlackProcessCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateSlackProcessCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/slack/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createSmtpProcessCredential
 * request: createSmtpProcessCredentialUsingPOST
 * url: createSmtpProcessCredentialUsingPOSTURL
 * method: createSmtpProcessCredentialUsingPOST_TYPE
 * raw_url: createSmtpProcessCredentialUsingPOST_RAW_URL
 * @param request - request
 */
export const createSmtpProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/smtp'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createSmtpProcessCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/smtp'
}
export const createSmtpProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createSmtpProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/smtp'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateSmtpProcessCredential
 * request: updateSmtpProcessCredentialUsingPUT
 * url: updateSmtpProcessCredentialUsingPUTURL
 * method: updateSmtpProcessCredentialUsingPUT_TYPE
 * raw_url: updateSmtpProcessCredentialUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateSmtpProcessCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/smtp/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateSmtpProcessCredentialUsingPUT_RAW_URL = function() {
  return '/api/process-credential/smtp/{id}'
}
export const updateSmtpProcessCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateSmtpProcessCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/smtp/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * testAwsCredential
 * request: testAwsCredentialUsingPOST
 * url: testAwsCredentialUsingPOSTURL
 * method: testAwsCredentialUsingPOST_TYPE
 * raw_url: testAwsCredentialUsingPOST_RAW_URL
 * @param values - values
 */
export const testAwsCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/test/aws'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['values'] !== undefined) {
    body = parameters['values']
  }
  if (parameters['values'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: values'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testAwsCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/test/aws'
}
export const testAwsCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testAwsCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/test/aws'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * testFlowyCredential
 * request: testFlowyCredentialUsingPOST
 * url: testFlowyCredentialUsingPOSTURL
 * method: testFlowyCredentialUsingPOST_TYPE
 * raw_url: testFlowyCredentialUsingPOST_RAW_URL
 * @param values - values
 */
export const testFlowyCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/test/flowy'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['values'] !== undefined) {
    body = parameters['values']
  }
  if (parameters['values'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: values'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testFlowyCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/test/flowy'
}
export const testFlowyCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testFlowyCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/test/flowy'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * testImapProcessCredential
 * request: testImapProcessCredentialUsingPOST
 * url: testImapProcessCredentialUsingPOSTURL
 * method: testImapProcessCredentialUsingPOST_TYPE
 * raw_url: testImapProcessCredentialUsingPOST_RAW_URL
 * @param values - values
 */
export const testImapProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/test/imap'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['values'] !== undefined) {
    body = parameters['values']
  }
  if (parameters['values'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: values'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testImapProcessCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/test/imap'
}
export const testImapProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testImapProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/test/imap'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * testJdbcProcessCredential
 * request: testJdbcProcessCredentialUsingPOST
 * url: testJdbcProcessCredentialUsingPOSTURL
 * method: testJdbcProcessCredentialUsingPOST_TYPE
 * raw_url: testJdbcProcessCredentialUsingPOST_RAW_URL
 * @param values - values
 */
export const testJdbcProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/test/jdbc'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['values'] !== undefined) {
    body = parameters['values']
  }
  if (parameters['values'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: values'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testJdbcProcessCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/test/jdbc'
}
export const testJdbcProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testJdbcProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/test/jdbc'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * testJmsCredential
 * request: testJmsCredentialUsingPOST
 * url: testJmsCredentialUsingPOSTURL
 * method: testJmsCredentialUsingPOST_TYPE
 * raw_url: testJmsCredentialUsingPOST_RAW_URL
 * @param values - values
 */
export const testJmsCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/test/jms'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['values'] !== undefined) {
    body = parameters['values']
  }
  if (parameters['values'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: values'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testJmsCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/test/jms'
}
export const testJmsCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testJmsCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/test/jms'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * testJwtProcessCredential
 * request: testJwtProcessCredentialUsingPOST
 * url: testJwtProcessCredentialUsingPOSTURL
 * method: testJwtProcessCredentialUsingPOST_TYPE
 * raw_url: testJwtProcessCredentialUsingPOST_RAW_URL
 * @param values - values
 */
export const testJwtProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/test/jwt'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['values'] !== undefined) {
    body = parameters['values']
  }
  if (parameters['values'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: values'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testJwtProcessCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/test/jwt'
}
export const testJwtProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testJwtProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/test/jwt'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * testKafkaCredential
 * request: testKafkaCredentialUsingPOST
 * url: testKafkaCredentialUsingPOSTURL
 * method: testKafkaCredentialUsingPOST_TYPE
 * raw_url: testKafkaCredentialUsingPOST_RAW_URL
 * @param values - values
 */
export const testKafkaCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/test/kafka'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['values'] !== undefined) {
    body = parameters['values']
  }
  if (parameters['values'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: values'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testKafkaCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/test/kafka'
}
export const testKafkaCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testKafkaCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/test/kafka'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * testMongodbProcessCredential
 * request: testMongodbProcessCredentialUsingPOST
 * url: testMongodbProcessCredentialUsingPOSTURL
 * method: testMongodbProcessCredentialUsingPOST_TYPE
 * raw_url: testMongodbProcessCredentialUsingPOST_RAW_URL
 * @param values - values
 */
export const testMongodbProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/test/mongodb'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['values'] !== undefined) {
    body = parameters['values']
  }
  if (parameters['values'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: values'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testMongodbProcessCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/test/mongodb'
}
export const testMongodbProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testMongodbProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/test/mongodb'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * testPaymentSenseConnectECredential
 * request: testPaymentSenseConnectECredentialUsingPOST
 * url: testPaymentSenseConnectECredentialUsingPOSTURL
 * method: testPaymentSenseConnectECredentialUsingPOST_TYPE
 * raw_url: testPaymentSenseConnectECredentialUsingPOST_RAW_URL
 * @param values - values
 */
export const testPaymentSenseConnectECredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/test/payment-sense-connect-e'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['values'] !== undefined) {
    body = parameters['values']
  }
  if (parameters['values'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: values'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testPaymentSenseConnectECredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/test/payment-sense-connect-e'
}
export const testPaymentSenseConnectECredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testPaymentSenseConnectECredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/test/payment-sense-connect-e'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * testPaymentSensePacCredential
 * request: testPaymentSensePacCredentialUsingPOST
 * url: testPaymentSensePacCredentialUsingPOSTURL
 * method: testPaymentSensePacCredentialUsingPOST_TYPE
 * raw_url: testPaymentSensePacCredentialUsingPOST_RAW_URL
 * @param values - values
 */
export const testPaymentSensePacCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/test/payment-sense-pac'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['values'] !== undefined) {
    body = parameters['values']
  }
  if (parameters['values'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: values'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testPaymentSensePacCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/test/payment-sense-pac'
}
export const testPaymentSensePacCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testPaymentSensePacCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/test/payment-sense-pac'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * testRabbitmqCredential
 * request: testRabbitmqCredentialUsingPOST
 * url: testRabbitmqCredentialUsingPOSTURL
 * method: testRabbitmqCredentialUsingPOST_TYPE
 * raw_url: testRabbitmqCredentialUsingPOST_RAW_URL
 * @param values - values
 */
export const testRabbitmqCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/test/rabbitmq'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['values'] !== undefined) {
    body = parameters['values']
  }
  if (parameters['values'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: values'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testRabbitmqCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/test/rabbitmq'
}
export const testRabbitmqCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testRabbitmqCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/test/rabbitmq'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * testSlackProcessCredential
 * request: testSlackProcessCredentialUsingPOST
 * url: testSlackProcessCredentialUsingPOSTURL
 * method: testSlackProcessCredentialUsingPOST_TYPE
 * raw_url: testSlackProcessCredentialUsingPOST_RAW_URL
 * @param values - values
 */
export const testSlackProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/test/slack'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['values'] !== undefined) {
    body = parameters['values']
  }
  if (parameters['values'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: values'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testSlackProcessCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/test/slack'
}
export const testSlackProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testSlackProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/test/slack'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * testSmtpProcessCredential
 * request: testSmtpProcessCredentialUsingPOST
 * url: testSmtpProcessCredentialUsingPOSTURL
 * method: testSmtpProcessCredentialUsingPOST_TYPE
 * raw_url: testSmtpProcessCredentialUsingPOST_RAW_URL
 * @param values - values
 */
export const testSmtpProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/test/smtp'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['values'] !== undefined) {
    body = parameters['values']
  }
  if (parameters['values'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: values'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testSmtpProcessCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/test/smtp'
}
export const testSmtpProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testSmtpProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/test/smtp'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * testTwilioProcessCredential
 * request: testTwilioProcessCredentialUsingPOST
 * url: testTwilioProcessCredentialUsingPOSTURL
 * method: testTwilioProcessCredentialUsingPOST_TYPE
 * raw_url: testTwilioProcessCredentialUsingPOST_RAW_URL
 * @param values - values
 */
export const testTwilioProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/test/twilio'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['values'] !== undefined) {
    body = parameters['values']
  }
  if (parameters['values'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: values'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testTwilioProcessCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/test/twilio'
}
export const testTwilioProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testTwilioProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/test/twilio'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createTwilioProcessCredential
 * request: createTwilioProcessCredentialUsingPOST
 * url: createTwilioProcessCredentialUsingPOSTURL
 * method: createTwilioProcessCredentialUsingPOST_TYPE
 * raw_url: createTwilioProcessCredentialUsingPOST_RAW_URL
 * @param request - request
 */
export const createTwilioProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/twilio'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createTwilioProcessCredentialUsingPOST_RAW_URL = function() {
  return '/api/process-credential/twilio'
}
export const createTwilioProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createTwilioProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/twilio'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateTwilioProcessCredential
 * request: updateTwilioProcessCredentialUsingPUT
 * url: updateTwilioProcessCredentialUsingPUTURL
 * method: updateTwilioProcessCredentialUsingPUT_TYPE
 * raw_url: updateTwilioProcessCredentialUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateTwilioProcessCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/twilio/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTwilioProcessCredentialUsingPUT_RAW_URL = function() {
  return '/api/process-credential/twilio/{id}'
}
export const updateTwilioProcessCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTwilioProcessCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/twilio/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getProcessCredentialById
 * request: getProcessCredentialByIdUsingGET
 * url: getProcessCredentialByIdUsingGETURL
 * method: getProcessCredentialByIdUsingGET_TYPE
 * raw_url: getProcessCredentialByIdUsingGET_RAW_URL
 * @param id - id
 */
export const getProcessCredentialByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProcessCredentialByIdUsingGET_RAW_URL = function() {
  return '/api/process-credential/{id}'
}
export const getProcessCredentialByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getProcessCredentialByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteProcessCredential
 * request: deleteProcessCredentialUsingDELETE
 * url: deleteProcessCredentialUsingDELETEURL
 * method: deleteProcessCredentialUsingDELETE_TYPE
 * raw_url: deleteProcessCredentialUsingDELETE_RAW_URL
 * @param force - 
 * @param id - id
 */
export const deleteProcessCredentialUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/{id}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteProcessCredentialUsingDELETE_RAW_URL = function() {
  return '/api/process-credential/{id}'
}
export const deleteProcessCredentialUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteProcessCredentialUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/{id}'
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * disableProcessCredential
 * request: disableProcessCredentialUsingGET
 * url: disableProcessCredentialUsingGETURL
 * method: disableProcessCredentialUsingGET_TYPE
 * raw_url: disableProcessCredentialUsingGET_RAW_URL
 * @param id - id
 */
export const disableProcessCredentialUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const disableProcessCredentialUsingGET_RAW_URL = function() {
  return '/api/process-credential/{id}/disable'
}
export const disableProcessCredentialUsingGET_TYPE = function() {
  return 'get'
}
export const disableProcessCredentialUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * enableProcessCredential
 * request: enableProcessCredentialUsingGET
 * url: enableProcessCredentialUsingGETURL
 * method: enableProcessCredentialUsingGET_TYPE
 * raw_url: enableProcessCredentialUsingGET_RAW_URL
 * @param id - id
 */
export const enableProcessCredentialUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const enableProcessCredentialUsingGET_RAW_URL = function() {
  return '/api/process-credential/{id}/enable'
}
export const enableProcessCredentialUsingGET_TYPE = function() {
  return 'get'
}
export const enableProcessCredentialUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateProcessCredentialRoles
 * request: updateProcessCredentialRolesUsingPUT
 * url: updateProcessCredentialRolesUsingPUTURL
 * method: updateProcessCredentialRolesUsingPUT_TYPE
 * raw_url: updateProcessCredentialRolesUsingPUT_RAW_URL
 * @param id - id
 * @param resourceRolesRequest - resourceRolesRequest
 */
export const updateProcessCredentialRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-credential/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['resourceRolesRequest'] !== undefined) {
    body = parameters['resourceRolesRequest']
  }
  if (parameters['resourceRolesRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: resourceRolesRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateProcessCredentialRolesUsingPUT_RAW_URL = function() {
  return '/api/process-credential/{id}/roles'
}
export const updateProcessCredentialRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateProcessCredentialRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-credential/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getProcessExecutions
 * request: getProcessExecutionsUsingGET
 * url: getProcessExecutionsUsingGETURL
 * method: getProcessExecutionsUsingGET_TYPE
 * raw_url: getProcessExecutionsUsingGET_RAW_URL
 * @param dateFrom - 
 * @param dateTill - 
 * @param page - 
 * @param processId - 
 * @param size - 
 */
export const getProcessExecutionsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-execution'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProcessExecutionsUsingGET_RAW_URL = function() {
  return '/api/process-execution'
}
export const getProcessExecutionsUsingGET_TYPE = function() {
  return 'get'
}
export const getProcessExecutionsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-execution'
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getProcessStepLogs
 * request: getProcessStepLogsUsingGET
 * url: getProcessStepLogsUsingGETURL
 * method: getProcessStepLogsUsingGET_TYPE
 * raw_url: getProcessStepLogsUsingGET_RAW_URL
 * @param dateFrom - 
 * @param dateTill - 
 * @param eventId - 
 * @param page - 
 * @param processId - 
 * @param size - 
 * @param status - 
 * @param stepId - 
 * @param stepType - 
 */
export const getProcessStepLogsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-step-log'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['eventId'] !== undefined) {
    queryParameters['eventId'] = parameters['eventId']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['stepId'] !== undefined) {
    queryParameters['stepId'] = parameters['stepId']
  }
  if (parameters['stepType'] !== undefined) {
    queryParameters['stepType'] = parameters['stepType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProcessStepLogsUsingGET_RAW_URL = function() {
  return '/api/process-step-log'
}
export const getProcessStepLogsUsingGET_TYPE = function() {
  return 'get'
}
export const getProcessStepLogsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-step-log'
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['eventId'] !== undefined) {
    queryParameters['eventId'] = parameters['eventId']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['stepId'] !== undefined) {
    queryParameters['stepId'] = parameters['stepId']
  }
  if (parameters['stepType'] !== undefined) {
    queryParameters['stepType'] = parameters['stepType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getProcessStepFailures
 * request: getProcessStepFailuresUsingGET
 * url: getProcessStepFailuresUsingGETURL
 * method: getProcessStepFailuresUsingGET_TYPE
 * raw_url: getProcessStepFailuresUsingGET_RAW_URL
 * @param dateFrom - 
 * @param dateTill - 
 * @param page - 
 * @param size - 
 */
export const getProcessStepFailuresUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-step-log/failures'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateFrom'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dateFrom'))
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['dateTill'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dateTill'))
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProcessStepFailuresUsingGET_RAW_URL = function() {
  return '/api/process-step-log/failures'
}
export const getProcessStepFailuresUsingGET_TYPE = function() {
  return 'get'
}
export const getProcessStepFailuresUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-step-log/failures'
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getProcessStepLogCache
 * request: getProcessStepLogCacheUsingGET
 * url: getProcessStepLogCacheUsingGETURL
 * method: getProcessStepLogCacheUsingGET_TYPE
 * raw_url: getProcessStepLogCacheUsingGET_RAW_URL
 * @param id - id
 */
export const getProcessStepLogCacheUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process-step-log/{id}/cache'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProcessStepLogCacheUsingGET_RAW_URL = function() {
  return '/api/process-step-log/{id}/cache'
}
export const getProcessStepLogCacheUsingGET_TYPE = function() {
  return 'get'
}
export const getProcessStepLogCacheUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process-step-log/{id}/cache'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getProcessById
 * request: getProcessByIdUsingGET
 * url: getProcessByIdUsingGETURL
 * method: getProcessByIdUsingGET_TYPE
 * raw_url: getProcessByIdUsingGET_RAW_URL
 * @param id - id
 */
export const getProcessByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProcessByIdUsingGET_RAW_URL = function() {
  return '/api/process/{id}'
}
export const getProcessByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getProcessByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateProcess
 * request: updateProcessUsingPUT
 * url: updateProcessUsingPUTURL
 * method: updateProcessUsingPUT_TYPE
 * raw_url: updateProcessUsingPUT_RAW_URL
 * @param id - id
 * @param processRequest - processRequest
 */
export const updateProcessUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['processRequest'] !== undefined) {
    body = parameters['processRequest']
  }
  if (parameters['processRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: processRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateProcessUsingPUT_RAW_URL = function() {
  return '/api/process/{id}'
}
export const updateProcessUsingPUT_TYPE = function() {
  return 'put'
}
export const updateProcessUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteProcess
 * request: deleteProcessUsingDELETE
 * url: deleteProcessUsingDELETEURL
 * method: deleteProcessUsingDELETE_TYPE
 * raw_url: deleteProcessUsingDELETE_RAW_URL
 * @param force - 
 * @param id - id
 */
export const deleteProcessUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process/{id}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteProcessUsingDELETE_RAW_URL = function() {
  return '/api/process/{id}'
}
export const deleteProcessUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteProcessUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process/{id}'
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * disableProcess
 * request: disableProcessUsingGET
 * url: disableProcessUsingGETURL
 * method: disableProcessUsingGET_TYPE
 * raw_url: disableProcessUsingGET_RAW_URL
 * @param id - id
 */
export const disableProcessUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const disableProcessUsingGET_RAW_URL = function() {
  return '/api/process/{id}/disable'
}
export const disableProcessUsingGET_TYPE = function() {
  return 'get'
}
export const disableProcessUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * enableProcess
 * request: enableProcessUsingGET
 * url: enableProcessUsingGETURL
 * method: enableProcessUsingGET_TYPE
 * raw_url: enableProcessUsingGET_RAW_URL
 * @param id - id
 */
export const enableProcessUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const enableProcessUsingGET_RAW_URL = function() {
  return '/api/process/{id}/enable'
}
export const enableProcessUsingGET_TYPE = function() {
  return 'get'
}
export const enableProcessUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateProcessRoles
 * request: updateProcessRolesUsingPUT
 * url: updateProcessRolesUsingPUTURL
 * method: updateProcessRolesUsingPUT_TYPE
 * raw_url: updateProcessRolesUsingPUT_RAW_URL
 * @param id - id
 * @param resourceRolesRequest - resourceRolesRequest
 */
export const updateProcessRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['resourceRolesRequest'] !== undefined) {
    body = parameters['resourceRolesRequest']
  }
  if (parameters['resourceRolesRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: resourceRolesRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateProcessRolesUsingPUT_RAW_URL = function() {
  return '/api/process/{id}/roles'
}
export const updateProcessRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateProcessRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getProcessStatistics
 * request: getProcessStatisticsUsingGET
 * url: getProcessStatisticsUsingGETURL
 * method: getProcessStatisticsUsingGET_TYPE
 * raw_url: getProcessStatisticsUsingGET_RAW_URL
 * @param id - id
 */
export const getProcessStatisticsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/process/{id}/statistics'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProcessStatisticsUsingGET_RAW_URL = function() {
  return '/api/process/{id}/statistics'
}
export const getProcessStatisticsUsingGET_TYPE = function() {
  return 'get'
}
export const getProcessStatisticsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/process/{id}/statistics'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * regexTest
 * request: regexTestUsingPOST
 * url: regexTestUsingPOSTURL
 * method: regexTestUsingPOST_TYPE
 * raw_url: regexTestUsingPOST_RAW_URL
 * @param regexTestRequest - regexTestRequest
 */
export const regexTestUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/regex/test'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['regexTestRequest'] !== undefined) {
    body = parameters['regexTestRequest']
  }
  if (parameters['regexTestRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regexTestRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const regexTestUsingPOST_RAW_URL = function() {
  return '/api/regex/test'
}
export const regexTestUsingPOST_TYPE = function() {
  return 'post'
}
export const regexTestUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/regex/test'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getRoles
 * request: getRolesUsingGET
 * url: getRolesUsingGETURL
 * method: getRolesUsingGET_TYPE
 * raw_url: getRolesUsingGET_RAW_URL
 * @param name - 
 * @param orderDirection - 
 * @param orderField - 
 * @param page - 
 * @param size - 
 */
export const getRolesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/role'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getRolesUsingGET_RAW_URL = function() {
  return '/api/role'
}
export const getRolesUsingGET_TYPE = function() {
  return 'get'
}
export const getRolesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/role'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createRole
 * request: createRoleUsingPOST
 * url: createRoleUsingPOSTURL
 * method: createRoleUsingPOST_TYPE
 * raw_url: createRoleUsingPOST_RAW_URL
 * @param request - request
 */
export const createRoleUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/role'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createRoleUsingPOST_RAW_URL = function() {
  return '/api/role'
}
export const createRoleUsingPOST_TYPE = function() {
  return 'post'
}
export const createRoleUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/role'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getRoleById
 * request: getRoleByIdUsingGET
 * url: getRoleByIdUsingGETURL
 * method: getRoleByIdUsingGET_TYPE
 * raw_url: getRoleByIdUsingGET_RAW_URL
 * @param id - id
 */
export const getRoleByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/role/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getRoleByIdUsingGET_RAW_URL = function() {
  return '/api/role/{id}'
}
export const getRoleByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getRoleByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/role/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateRole
 * request: updateRoleUsingPUT
 * url: updateRoleUsingPUTURL
 * method: updateRoleUsingPUT_TYPE
 * raw_url: updateRoleUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateRoleUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/role/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateRoleUsingPUT_RAW_URL = function() {
  return '/api/role/{id}'
}
export const updateRoleUsingPUT_TYPE = function() {
  return 'put'
}
export const updateRoleUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/role/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteRole
 * request: deleteRoleUsingDELETE
 * url: deleteRoleUsingDELETEURL
 * method: deleteRoleUsingDELETE_TYPE
 * raw_url: deleteRoleUsingDELETE_RAW_URL
 * @param force - 
 * @param id - id
 */
export const deleteRoleUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/role/{id}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteRoleUsingDELETE_RAW_URL = function() {
  return '/api/role/{id}'
}
export const deleteRoleUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteRoleUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/role/{id}'
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * setUserPassword
 * request: setUserPasswordUsingPOST
 * url: setUserPasswordUsingPOSTURL
 * method: setUserPasswordUsingPOST_TYPE
 * raw_url: setUserPasswordUsingPOST_RAW_URL
 * @param request - request
 */
export const setUserPasswordUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/set-password'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const setUserPasswordUsingPOST_RAW_URL = function() {
  return '/api/set-password'
}
export const setUserPasswordUsingPOST_TYPE = function() {
  return 'post'
}
export const setUserPasswordUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/set-password'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getSettings
 * request: getSettingsUsingGET
 * url: getSettingsUsingGETURL
 * method: getSettingsUsingGET_TYPE
 * raw_url: getSettingsUsingGET_RAW_URL
 * @param name - 
 * @param orderDirection - 
 * @param orderField - 
 * @param page - 
 * @param size - 
 * @param status - 
 * @param type - 
 */
export const getSettingsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/setting'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSettingsUsingGET_RAW_URL = function() {
  return '/api/setting'
}
export const getSettingsUsingGET_TYPE = function() {
  return 'get'
}
export const getSettingsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/setting'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createFrontendSetting
 * request: createFrontendSettingUsingPOST
 * url: createFrontendSettingUsingPOSTURL
 * method: createFrontendSettingUsingPOST_TYPE
 * raw_url: createFrontendSettingUsingPOST_RAW_URL
 * @param request - request
 */
export const createFrontendSettingUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/setting/frontend'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createFrontendSettingUsingPOST_RAW_URL = function() {
  return '/api/setting/frontend'
}
export const createFrontendSettingUsingPOST_TYPE = function() {
  return 'post'
}
export const createFrontendSettingUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/setting/frontend'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateFrontendSetting
 * request: updateFrontendSettingUsingPUT
 * url: updateFrontendSettingUsingPUTURL
 * method: updateFrontendSettingUsingPUT_TYPE
 * raw_url: updateFrontendSettingUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateFrontendSettingUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/setting/frontend/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateFrontendSettingUsingPUT_RAW_URL = function() {
  return '/api/setting/frontend/{id}'
}
export const updateFrontendSettingUsingPUT_TYPE = function() {
  return 'put'
}
export const updateFrontendSettingUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/setting/frontend/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createInstanceAdminSetting
 * request: createInstanceAdminSettingUsingPOST
 * url: createInstanceAdminSettingUsingPOSTURL
 * method: createInstanceAdminSettingUsingPOST_TYPE
 * raw_url: createInstanceAdminSettingUsingPOST_RAW_URL
 * @param request - request
 */
export const createInstanceAdminSettingUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/setting/instance-admin'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createInstanceAdminSettingUsingPOST_RAW_URL = function() {
  return '/api/setting/instance-admin'
}
export const createInstanceAdminSettingUsingPOST_TYPE = function() {
  return 'post'
}
export const createInstanceAdminSettingUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/setting/instance-admin'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateInstanceAdminSetting
 * request: updateInstanceAdminSettingUsingPUT
 * url: updateInstanceAdminSettingUsingPUTURL
 * method: updateInstanceAdminSettingUsingPUT_TYPE
 * raw_url: updateInstanceAdminSettingUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateInstanceAdminSettingUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/setting/instance-admin/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateInstanceAdminSettingUsingPUT_RAW_URL = function() {
  return '/api/setting/instance-admin/{id}'
}
export const updateInstanceAdminSettingUsingPUT_TYPE = function() {
  return 'put'
}
export const updateInstanceAdminSettingUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/setting/instance-admin/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createInstanceProcessingSetting
 * request: createInstanceProcessingSettingUsingPOST
 * url: createInstanceProcessingSettingUsingPOSTURL
 * method: createInstanceProcessingSettingUsingPOST_TYPE
 * raw_url: createInstanceProcessingSettingUsingPOST_RAW_URL
 * @param request - request
 */
export const createInstanceProcessingSettingUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/setting/instance-processing'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createInstanceProcessingSettingUsingPOST_RAW_URL = function() {
  return '/api/setting/instance-processing'
}
export const createInstanceProcessingSettingUsingPOST_TYPE = function() {
  return 'post'
}
export const createInstanceProcessingSettingUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/setting/instance-processing'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateInstanceProcessingSetting
 * request: updateInstanceProcessingSettingUsingPUT
 * url: updateInstanceProcessingSettingUsingPUTURL
 * method: updateInstanceProcessingSettingUsingPUT_TYPE
 * raw_url: updateInstanceProcessingSettingUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateInstanceProcessingSettingUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/setting/instance-processing/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateInstanceProcessingSettingUsingPUT_RAW_URL = function() {
  return '/api/setting/instance-processing/{id}'
}
export const updateInstanceProcessingSettingUsingPUT_TYPE = function() {
  return 'put'
}
export const updateInstanceProcessingSettingUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/setting/instance-processing/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createPluginSetting
 * request: createPluginSettingUsingPOST
 * url: createPluginSettingUsingPOSTURL
 * method: createPluginSettingUsingPOST_TYPE
 * raw_url: createPluginSettingUsingPOST_RAW_URL
 * @param request - request
 */
export const createPluginSettingUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/setting/plugin'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createPluginSettingUsingPOST_RAW_URL = function() {
  return '/api/setting/plugin'
}
export const createPluginSettingUsingPOST_TYPE = function() {
  return 'post'
}
export const createPluginSettingUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/setting/plugin'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updatePluginSetting
 * request: updatePluginSettingUsingPUT
 * url: updatePluginSettingUsingPUTURL
 * method: updatePluginSettingUsingPUT_TYPE
 * raw_url: updatePluginSettingUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updatePluginSettingUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/setting/plugin/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updatePluginSettingUsingPUT_RAW_URL = function() {
  return '/api/setting/plugin/{id}'
}
export const updatePluginSettingUsingPUT_TYPE = function() {
  return 'put'
}
export const updatePluginSettingUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/setting/plugin/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createProcessSetting
 * request: createProcessSettingUsingPOST
 * url: createProcessSettingUsingPOSTURL
 * method: createProcessSettingUsingPOST_TYPE
 * raw_url: createProcessSettingUsingPOST_RAW_URL
 * @param request - request
 */
export const createProcessSettingUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/setting/process'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createProcessSettingUsingPOST_RAW_URL = function() {
  return '/api/setting/process'
}
export const createProcessSettingUsingPOST_TYPE = function() {
  return 'post'
}
export const createProcessSettingUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/setting/process'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateProcessSetting
 * request: updateProcessSettingUsingPUT
 * url: updateProcessSettingUsingPUTURL
 * method: updateProcessSettingUsingPUT_TYPE
 * raw_url: updateProcessSettingUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateProcessSettingUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/setting/process/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateProcessSettingUsingPUT_RAW_URL = function() {
  return '/api/setting/process/{id}'
}
export const updateProcessSettingUsingPUT_TYPE = function() {
  return 'put'
}
export const updateProcessSettingUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/setting/process/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getSettingById
 * request: getSettingByIdUsingGET
 * url: getSettingByIdUsingGETURL
 * method: getSettingByIdUsingGET_TYPE
 * raw_url: getSettingByIdUsingGET_RAW_URL
 * @param id - id
 */
export const getSettingByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/setting/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSettingByIdUsingGET_RAW_URL = function() {
  return '/api/setting/{id}'
}
export const getSettingByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getSettingByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/setting/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteSetting
 * request: deleteSettingUsingDELETE
 * url: deleteSettingUsingDELETEURL
 * method: deleteSettingUsingDELETE_TYPE
 * raw_url: deleteSettingUsingDELETE_RAW_URL
 * @param force - 
 * @param id - id
 */
export const deleteSettingUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/setting/{id}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteSettingUsingDELETE_RAW_URL = function() {
  return '/api/setting/{id}'
}
export const deleteSettingUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteSettingUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/setting/{id}'
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * disableSetting
 * request: disableSettingUsingGET
 * url: disableSettingUsingGETURL
 * method: disableSettingUsingGET_TYPE
 * raw_url: disableSettingUsingGET_RAW_URL
 * @param id - id
 */
export const disableSettingUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/setting/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const disableSettingUsingGET_RAW_URL = function() {
  return '/api/setting/{id}/disable'
}
export const disableSettingUsingGET_TYPE = function() {
  return 'get'
}
export const disableSettingUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/setting/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * enableSetting
 * request: enableSettingUsingGET
 * url: enableSettingUsingGETURL
 * method: enableSettingUsingGET_TYPE
 * raw_url: enableSettingUsingGET_RAW_URL
 * @param id - id
 */
export const enableSettingUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/setting/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const enableSettingUsingGET_RAW_URL = function() {
  return '/api/setting/{id}/enable'
}
export const enableSettingUsingGET_TYPE = function() {
  return 'get'
}
export const enableSettingUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/setting/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateSettingRoles
 * request: updateSettingRolesUsingPUT
 * url: updateSettingRolesUsingPUTURL
 * method: updateSettingRolesUsingPUT_TYPE
 * raw_url: updateSettingRolesUsingPUT_RAW_URL
 * @param id - id
 * @param resourceRolesRequest - resourceRolesRequest
 */
export const updateSettingRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/setting/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['resourceRolesRequest'] !== undefined) {
    body = parameters['resourceRolesRequest']
  }
  if (parameters['resourceRolesRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: resourceRolesRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateSettingRolesUsingPUT_RAW_URL = function() {
  return '/api/setting/{id}/roles'
}
export const updateSettingRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateSettingRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/setting/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTelemetryDaily
 * request: getTelemetryDailyUsingGET
 * url: getTelemetryDailyUsingGETURL
 * method: getTelemetryDailyUsingGET_TYPE
 * raw_url: getTelemetryDailyUsingGET_RAW_URL
 * @param day - 
 * @param page - 
 * @param size - 
 */
export const getTelemetryDailyUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/telemetry/daily'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['day'] !== undefined) {
    queryParameters['day'] = parameters['day']
  }
  if (parameters['day'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: day'))
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTelemetryDailyUsingGET_RAW_URL = function() {
  return '/api/telemetry/daily'
}
export const getTelemetryDailyUsingGET_TYPE = function() {
  return 'get'
}
export const getTelemetryDailyUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/telemetry/daily'
  if (parameters['day'] !== undefined) {
    queryParameters['day'] = parameters['day']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTelemetryMonthly
 * request: getTelemetryMonthlyUsingGET
 * url: getTelemetryMonthlyUsingGETURL
 * method: getTelemetryMonthlyUsingGET_TYPE
 * raw_url: getTelemetryMonthlyUsingGET_RAW_URL
 * @param month - 
 * @param page - 
 * @param size - 
 */
export const getTelemetryMonthlyUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/telemetry/monthly'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['month'] !== undefined) {
    queryParameters['month'] = parameters['month']
  }
  if (parameters['month'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: month'))
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTelemetryMonthlyUsingGET_RAW_URL = function() {
  return '/api/telemetry/monthly'
}
export const getTelemetryMonthlyUsingGET_TYPE = function() {
  return 'get'
}
export const getTelemetryMonthlyUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/telemetry/monthly'
  if (parameters['month'] !== undefined) {
    queryParameters['month'] = parameters['month']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTelemetryYearly
 * request: getTelemetryYearlyUsingGET
 * url: getTelemetryYearlyUsingGETURL
 * method: getTelemetryYearlyUsingGET_TYPE
 * raw_url: getTelemetryYearlyUsingGET_RAW_URL
 * @param page - 
 * @param size - 
 * @param year - 
 */
export const getTelemetryYearlyUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/telemetry/yearly'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['year'] !== undefined) {
    queryParameters['year'] = parameters['year']
  }
  if (parameters['year'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: year'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTelemetryYearlyUsingGET_RAW_URL = function() {
  return '/api/telemetry/yearly'
}
export const getTelemetryYearlyUsingGET_TYPE = function() {
  return 'get'
}
export const getTelemetryYearlyUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/telemetry/yearly'
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['year'] !== undefined) {
    queryParameters['year'] = parameters['year']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTextTemplates
 * request: getTextTemplatesUsingGET
 * url: getTextTemplatesUsingGETURL
 * method: getTextTemplatesUsingGET_TYPE
 * raw_url: getTextTemplatesUsingGET_RAW_URL
 * @param name - 
 * @param orderDirection - 
 * @param orderField - 
 * @param page - 
 * @param size - 
 */
export const getTextTemplatesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/text-template'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTextTemplatesUsingGET_RAW_URL = function() {
  return '/api/text-template'
}
export const getTextTemplatesUsingGET_TYPE = function() {
  return 'get'
}
export const getTextTemplatesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/text-template'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createTextTemplate
 * request: createTextTemplateUsingPOST
 * url: createTextTemplateUsingPOSTURL
 * method: createTextTemplateUsingPOST_TYPE
 * raw_url: createTextTemplateUsingPOST_RAW_URL
 * @param request - request
 */
export const createTextTemplateUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/text-template'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createTextTemplateUsingPOST_RAW_URL = function() {
  return '/api/text-template'
}
export const createTextTemplateUsingPOST_TYPE = function() {
  return 'post'
}
export const createTextTemplateUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/text-template'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createPdf
 * request: createPdfUsingPOST
 * url: createPdfUsingPOSTURL
 * method: createPdfUsingPOST_TYPE
 * raw_url: createPdfUsingPOST_RAW_URL
 * @param request - request
 */
export const createPdfUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/text-template/pdf'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createPdfUsingPOST_RAW_URL = function() {
  return '/api/text-template/pdf'
}
export const createPdfUsingPOST_TYPE = function() {
  return 'post'
}
export const createPdfUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/text-template/pdf'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateTextTemplateRoles
 * request: updateTextTemplateRolesUsingPUT
 * url: updateTextTemplateRolesUsingPUTURL
 * method: updateTextTemplateRolesUsingPUT_TYPE
 * raw_url: updateTextTemplateRolesUsingPUT_RAW_URL
 * @param id - id
 * @param resourceRolesRequest - resourceRolesRequest
 */
export const updateTextTemplateRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/text-template/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['resourceRolesRequest'] !== undefined) {
    body = parameters['resourceRolesRequest']
  }
  if (parameters['resourceRolesRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: resourceRolesRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTextTemplateRolesUsingPUT_RAW_URL = function() {
  return '/api/text-template/{id}/roles'
}
export const updateTextTemplateRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTextTemplateRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/text-template/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTextTemplateById
 * request: getTextTemplateByIdUsingGET
 * url: getTextTemplateByIdUsingGETURL
 * method: getTextTemplateByIdUsingGET_TYPE
 * raw_url: getTextTemplateByIdUsingGET_RAW_URL
 * @param templateId - templateId
 */
export const getTextTemplateByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/text-template/{templateId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters['templateId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: templateId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTextTemplateByIdUsingGET_RAW_URL = function() {
  return '/api/text-template/{templateId}'
}
export const getTextTemplateByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getTextTemplateByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/text-template/{templateId}'
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateTextTemplate
 * request: updateTextTemplateUsingPUT
 * url: updateTextTemplateUsingPUTURL
 * method: updateTextTemplateUsingPUT_TYPE
 * raw_url: updateTextTemplateUsingPUT_RAW_URL
 * @param request - request
 * @param templateId - templateId
 */
export const updateTextTemplateUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/text-template/{templateId}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters['templateId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: templateId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTextTemplateUsingPUT_RAW_URL = function() {
  return '/api/text-template/{templateId}'
}
export const updateTextTemplateUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTextTemplateUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/text-template/{templateId}'
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteTextTemplate
 * request: deleteTextTemplateUsingDELETE
 * url: deleteTextTemplateUsingDELETEURL
 * method: deleteTextTemplateUsingDELETE_TYPE
 * raw_url: deleteTextTemplateUsingDELETE_RAW_URL
 * @param force - 
 * @param templateId - templateId
 */
export const deleteTextTemplateUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/text-template/{templateId}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters['templateId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: templateId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteTextTemplateUsingDELETE_RAW_URL = function() {
  return '/api/text-template/{templateId}'
}
export const deleteTextTemplateUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteTextTemplateUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/text-template/{templateId}'
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTextTemplateTranslations
 * request: getTextTemplateTranslationsUsingGET
 * url: getTextTemplateTranslationsUsingGETURL
 * method: getTextTemplateTranslationsUsingGET_TYPE
 * raw_url: getTextTemplateTranslationsUsingGET_RAW_URL
 * @param name - 
 * @param orderDirection - 
 * @param orderField - 
 * @param page - 
 * @param size - 
 * @param templateId - templateId
 */
export const getTextTemplateTranslationsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/text-template/{templateId}/translation'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters['templateId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: templateId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTextTemplateTranslationsUsingGET_RAW_URL = function() {
  return '/api/text-template/{templateId}/translation'
}
export const getTextTemplateTranslationsUsingGET_TYPE = function() {
  return 'get'
}
export const getTextTemplateTranslationsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/text-template/{templateId}/translation'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createTextTemplateTranslation
 * request: createTextTemplateTranslationUsingPOST
 * url: createTextTemplateTranslationUsingPOSTURL
 * method: createTextTemplateTranslationUsingPOST_TYPE
 * raw_url: createTextTemplateTranslationUsingPOST_RAW_URL
 * @param request - request
 * @param templateId - templateId
 */
export const createTextTemplateTranslationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/text-template/{templateId}/translation'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters['templateId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: templateId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createTextTemplateTranslationUsingPOST_RAW_URL = function() {
  return '/api/text-template/{templateId}/translation'
}
export const createTextTemplateTranslationUsingPOST_TYPE = function() {
  return 'post'
}
export const createTextTemplateTranslationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/text-template/{templateId}/translation'
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTextTemplateTranslation
 * request: getTextTemplateTranslationUsingGET
 * url: getTextTemplateTranslationUsingGETURL
 * method: getTextTemplateTranslationUsingGET_TYPE
 * raw_url: getTextTemplateTranslationUsingGET_RAW_URL
 * @param templateId - templateId
 * @param translationId - translationId
 */
export const getTextTemplateTranslationUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/text-template/{templateId}/translation/{translationId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters['templateId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: templateId'))
  }
  path = path.replace('{translationId}', `${parameters['translationId']}`)
  if (parameters['translationId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: translationId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTextTemplateTranslationUsingGET_RAW_URL = function() {
  return '/api/text-template/{templateId}/translation/{translationId}'
}
export const getTextTemplateTranslationUsingGET_TYPE = function() {
  return 'get'
}
export const getTextTemplateTranslationUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/text-template/{templateId}/translation/{translationId}'
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  path = path.replace('{translationId}', `${parameters['translationId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateTextTemplateTranslation
 * request: updateTextTemplateTranslationUsingPUT
 * url: updateTextTemplateTranslationUsingPUTURL
 * method: updateTextTemplateTranslationUsingPUT_TYPE
 * raw_url: updateTextTemplateTranslationUsingPUT_RAW_URL
 * @param request - request
 * @param templateId - templateId
 * @param translationId - translationId
 */
export const updateTextTemplateTranslationUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/text-template/{templateId}/translation/{translationId}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters['templateId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: templateId'))
  }
  path = path.replace('{translationId}', `${parameters['translationId']}`)
  if (parameters['translationId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: translationId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTextTemplateTranslationUsingPUT_RAW_URL = function() {
  return '/api/text-template/{templateId}/translation/{translationId}'
}
export const updateTextTemplateTranslationUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTextTemplateTranslationUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/text-template/{templateId}/translation/{translationId}'
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  path = path.replace('{translationId}', `${parameters['translationId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteTextTemplateTranslation
 * request: deleteTextTemplateTranslationUsingDELETE
 * url: deleteTextTemplateTranslationUsingDELETEURL
 * method: deleteTextTemplateTranslationUsingDELETE_TYPE
 * raw_url: deleteTextTemplateTranslationUsingDELETE_RAW_URL
 * @param templateId - templateId
 * @param translationId - translationId
 */
export const deleteTextTemplateTranslationUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/text-template/{templateId}/translation/{translationId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters['templateId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: templateId'))
  }
  path = path.replace('{translationId}', `${parameters['translationId']}`)
  if (parameters['translationId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: translationId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteTextTemplateTranslationUsingDELETE_RAW_URL = function() {
  return '/api/text-template/{templateId}/translation/{translationId}'
}
export const deleteTextTemplateTranslationUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteTextTemplateTranslationUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/text-template/{templateId}/translation/{translationId}'
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  path = path.replace('{translationId}', `${parameters['translationId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTranslations
 * request: getTranslationsUsingGET
 * url: getTranslationsUsingGETURL
 * method: getTranslationsUsingGET_TYPE
 * raw_url: getTranslationsUsingGET_RAW_URL
 * @param name - 
 * @param orderDirection - 
 * @param orderField - 
 * @param page - 
 * @param size - 
 */
export const getTranslationsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/translation'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTranslationsUsingGET_RAW_URL = function() {
  return '/api/translation'
}
export const getTranslationsUsingGET_TYPE = function() {
  return 'get'
}
export const getTranslationsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/translation'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createTranslation
 * request: createTranslationUsingPOST
 * url: createTranslationUsingPOSTURL
 * method: createTranslationUsingPOST_TYPE
 * raw_url: createTranslationUsingPOST_RAW_URL
 * @param request - request
 */
export const createTranslationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/translation'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createTranslationUsingPOST_RAW_URL = function() {
  return '/api/translation'
}
export const createTranslationUsingPOST_TYPE = function() {
  return 'post'
}
export const createTranslationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/translation'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTranslation
 * request: getTranslationUsingGET
 * url: getTranslationUsingGETURL
 * method: getTranslationUsingGET_TYPE
 * raw_url: getTranslationUsingGET_RAW_URL
 * @param id - id
 */
export const getTranslationUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/translation/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTranslationUsingGET_RAW_URL = function() {
  return '/api/translation/{id}'
}
export const getTranslationUsingGET_TYPE = function() {
  return 'get'
}
export const getTranslationUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/translation/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateTranslationRoles
 * request: updateTranslationRolesUsingPUT
 * url: updateTranslationRolesUsingPUTURL
 * method: updateTranslationRolesUsingPUT_TYPE
 * raw_url: updateTranslationRolesUsingPUT_RAW_URL
 * @param id - id
 * @param resourceRolesRequest - resourceRolesRequest
 */
export const updateTranslationRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/translation/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['resourceRolesRequest'] !== undefined) {
    body = parameters['resourceRolesRequest']
  }
  if (parameters['resourceRolesRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: resourceRolesRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTranslationRolesUsingPUT_RAW_URL = function() {
  return '/api/translation/{id}/roles'
}
export const updateTranslationRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTranslationRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/translation/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateTranslation
 * request: updateTranslationUsingPUT
 * url: updateTranslationUsingPUTURL
 * method: updateTranslationUsingPUT_TYPE
 * raw_url: updateTranslationUsingPUT_RAW_URL
 * @param request - request
 * @param translationId - translationId
 */
export const updateTranslationUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/translation/{translationId}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  path = path.replace('{translationId}', `${parameters['translationId']}`)
  if (parameters['translationId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: translationId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTranslationUsingPUT_RAW_URL = function() {
  return '/api/translation/{translationId}'
}
export const updateTranslationUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTranslationUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/translation/{translationId}'
  path = path.replace('{translationId}', `${parameters['translationId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteTranslation
 * request: deleteTranslationUsingDELETE
 * url: deleteTranslationUsingDELETEURL
 * method: deleteTranslationUsingDELETE_TYPE
 * raw_url: deleteTranslationUsingDELETE_RAW_URL
 * @param force - 
 * @param translationId - translationId
 */
export const deleteTranslationUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/translation/{translationId}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  path = path.replace('{translationId}', `${parameters['translationId']}`)
  if (parameters['translationId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: translationId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteTranslationUsingDELETE_RAW_URL = function() {
  return '/api/translation/{translationId}'
}
export const deleteTranslationUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteTranslationUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/translation/{translationId}'
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  path = path.replace('{translationId}', `${parameters['translationId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTriggerCrons
 * request: getTriggerCronsUsingGET
 * url: getTriggerCronsUsingGETURL
 * method: getTriggerCronsUsingGET_TYPE
 * raw_url: getTriggerCronsUsingGET_RAW_URL
 * @param method - 
 * @param name - 
 * @param orderDirection - 
 * @param orderField - 
 * @param page - 
 * @param processId - 
 * @param size - 
 * @param status - 
 * @param url - 
 */
export const getTriggerCronsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-cron'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['method'] !== undefined) {
    queryParameters['method'] = parameters['method']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['url'] !== undefined) {
    queryParameters['url'] = parameters['url']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTriggerCronsUsingGET_RAW_URL = function() {
  return '/api/trigger-cron'
}
export const getTriggerCronsUsingGET_TYPE = function() {
  return 'get'
}
export const getTriggerCronsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-cron'
  if (parameters['method'] !== undefined) {
    queryParameters['method'] = parameters['method']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['url'] !== undefined) {
    queryParameters['url'] = parameters['url']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createTriggerCron
 * request: createTriggerCronUsingPOST
 * url: createTriggerCronUsingPOSTURL
 * method: createTriggerCronUsingPOST_TYPE
 * raw_url: createTriggerCronUsingPOST_RAW_URL
 * @param triggerCronRequest - triggerCronRequest
 */
export const createTriggerCronUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-cron'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['triggerCronRequest'] !== undefined) {
    body = parameters['triggerCronRequest']
  }
  if (parameters['triggerCronRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: triggerCronRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createTriggerCronUsingPOST_RAW_URL = function() {
  return '/api/trigger-cron'
}
export const createTriggerCronUsingPOST_TYPE = function() {
  return 'post'
}
export const createTriggerCronUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-cron'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTriggerCronById
 * request: getTriggerCronByIdUsingGET
 * url: getTriggerCronByIdUsingGETURL
 * method: getTriggerCronByIdUsingGET_TYPE
 * raw_url: getTriggerCronByIdUsingGET_RAW_URL
 * @param id - id
 */
export const getTriggerCronByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-cron/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTriggerCronByIdUsingGET_RAW_URL = function() {
  return '/api/trigger-cron/{id}'
}
export const getTriggerCronByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getTriggerCronByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-cron/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateTriggerCron
 * request: updateTriggerCronUsingPUT
 * url: updateTriggerCronUsingPUTURL
 * method: updateTriggerCronUsingPUT_TYPE
 * raw_url: updateTriggerCronUsingPUT_RAW_URL
 * @param id - id
 * @param triggerCronRequest - triggerCronRequest
 */
export const updateTriggerCronUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-cron/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['triggerCronRequest'] !== undefined) {
    body = parameters['triggerCronRequest']
  }
  if (parameters['triggerCronRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: triggerCronRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTriggerCronUsingPUT_RAW_URL = function() {
  return '/api/trigger-cron/{id}'
}
export const updateTriggerCronUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTriggerCronUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-cron/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteCronTrigger
 * request: deleteCronTriggerUsingDELETE
 * url: deleteCronTriggerUsingDELETEURL
 * method: deleteCronTriggerUsingDELETE_TYPE
 * raw_url: deleteCronTriggerUsingDELETE_RAW_URL
 * @param force - 
 * @param id - id
 */
export const deleteCronTriggerUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-cron/{id}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteCronTriggerUsingDELETE_RAW_URL = function() {
  return '/api/trigger-cron/{id}'
}
export const deleteCronTriggerUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteCronTriggerUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-cron/{id}'
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * disableTriggerCron
 * request: disableTriggerCronUsingGET
 * url: disableTriggerCronUsingGETURL
 * method: disableTriggerCronUsingGET_TYPE
 * raw_url: disableTriggerCronUsingGET_RAW_URL
 * @param id - id
 */
export const disableTriggerCronUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-cron/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const disableTriggerCronUsingGET_RAW_URL = function() {
  return '/api/trigger-cron/{id}/disable'
}
export const disableTriggerCronUsingGET_TYPE = function() {
  return 'get'
}
export const disableTriggerCronUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-cron/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * enableTriggerCron
 * request: enableTriggerCronUsingGET
 * url: enableTriggerCronUsingGETURL
 * method: enableTriggerCronUsingGET_TYPE
 * raw_url: enableTriggerCronUsingGET_RAW_URL
 * @param id - id
 */
export const enableTriggerCronUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-cron/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const enableTriggerCronUsingGET_RAW_URL = function() {
  return '/api/trigger-cron/{id}/enable'
}
export const enableTriggerCronUsingGET_TYPE = function() {
  return 'get'
}
export const enableTriggerCronUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-cron/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * execute
 * request: executeUsingGET
 * url: executeUsingGETURL
 * method: executeUsingGET_TYPE
 * raw_url: executeUsingGET_RAW_URL
 * @param id - id
 */
export const executeUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-cron/{id}/execute'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const executeUsingGET_RAW_URL = function() {
  return '/api/trigger-cron/{id}/execute'
}
export const executeUsingGET_TYPE = function() {
  return 'get'
}
export const executeUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-cron/{id}/execute'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateTriggerCronRoles
 * request: updateTriggerCronRolesUsingPUT
 * url: updateTriggerCronRolesUsingPUTURL
 * method: updateTriggerCronRolesUsingPUT_TYPE
 * raw_url: updateTriggerCronRolesUsingPUT_RAW_URL
 * @param id - id
 * @param resourceRolesRequest - resourceRolesRequest
 */
export const updateTriggerCronRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-cron/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['resourceRolesRequest'] !== undefined) {
    body = parameters['resourceRolesRequest']
  }
  if (parameters['resourceRolesRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: resourceRolesRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTriggerCronRolesUsingPUT_RAW_URL = function() {
  return '/api/trigger-cron/{id}/roles'
}
export const updateTriggerCronRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTriggerCronRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-cron/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTriggerMessagings
 * request: getTriggerMessagingsUsingGET
 * url: getTriggerMessagingsUsingGETURL
 * method: getTriggerMessagingsUsingGET_TYPE
 * raw_url: getTriggerMessagingsUsingGET_RAW_URL
 * @param method - 
 * @param name - 
 * @param orderDirection - 
 * @param orderField - 
 * @param page - 
 * @param processId - 
 * @param size - 
 * @param status - 
 * @param url - 
 */
export const getTriggerMessagingsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-messaging'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['method'] !== undefined) {
    queryParameters['method'] = parameters['method']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['url'] !== undefined) {
    queryParameters['url'] = parameters['url']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTriggerMessagingsUsingGET_RAW_URL = function() {
  return '/api/trigger-messaging'
}
export const getTriggerMessagingsUsingGET_TYPE = function() {
  return 'get'
}
export const getTriggerMessagingsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-messaging'
  if (parameters['method'] !== undefined) {
    queryParameters['method'] = parameters['method']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['url'] !== undefined) {
    queryParameters['url'] = parameters['url']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createTriggerMessaging
 * request: createTriggerMessagingUsingPOST
 * url: createTriggerMessagingUsingPOSTURL
 * method: createTriggerMessagingUsingPOST_TYPE
 * raw_url: createTriggerMessagingUsingPOST_RAW_URL
 * @param triggerMessagingRequest - triggerMessagingRequest
 */
export const createTriggerMessagingUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-messaging'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['triggerMessagingRequest'] !== undefined) {
    body = parameters['triggerMessagingRequest']
  }
  if (parameters['triggerMessagingRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: triggerMessagingRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createTriggerMessagingUsingPOST_RAW_URL = function() {
  return '/api/trigger-messaging'
}
export const createTriggerMessagingUsingPOST_TYPE = function() {
  return 'post'
}
export const createTriggerMessagingUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-messaging'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTriggerMessagingById
 * request: getTriggerMessagingByIdUsingGET
 * url: getTriggerMessagingByIdUsingGETURL
 * method: getTriggerMessagingByIdUsingGET_TYPE
 * raw_url: getTriggerMessagingByIdUsingGET_RAW_URL
 * @param id - id
 */
export const getTriggerMessagingByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-messaging/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTriggerMessagingByIdUsingGET_RAW_URL = function() {
  return '/api/trigger-messaging/{id}'
}
export const getTriggerMessagingByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getTriggerMessagingByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-messaging/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateTriggerMessaging
 * request: updateTriggerMessagingUsingPUT
 * url: updateTriggerMessagingUsingPUTURL
 * method: updateTriggerMessagingUsingPUT_TYPE
 * raw_url: updateTriggerMessagingUsingPUT_RAW_URL
 * @param id - id
 * @param triggerMessagingRequest - triggerMessagingRequest
 */
export const updateTriggerMessagingUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-messaging/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['triggerMessagingRequest'] !== undefined) {
    body = parameters['triggerMessagingRequest']
  }
  if (parameters['triggerMessagingRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: triggerMessagingRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTriggerMessagingUsingPUT_RAW_URL = function() {
  return '/api/trigger-messaging/{id}'
}
export const updateTriggerMessagingUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTriggerMessagingUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-messaging/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteMessagingTrigger
 * request: deleteMessagingTriggerUsingDELETE
 * url: deleteMessagingTriggerUsingDELETEURL
 * method: deleteMessagingTriggerUsingDELETE_TYPE
 * raw_url: deleteMessagingTriggerUsingDELETE_RAW_URL
 * @param force - 
 * @param id - id
 */
export const deleteMessagingTriggerUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-messaging/{id}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteMessagingTriggerUsingDELETE_RAW_URL = function() {
  return '/api/trigger-messaging/{id}'
}
export const deleteMessagingTriggerUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteMessagingTriggerUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-messaging/{id}'
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * disableTriggerMessaging
 * request: disableTriggerMessagingUsingGET
 * url: disableTriggerMessagingUsingGETURL
 * method: disableTriggerMessagingUsingGET_TYPE
 * raw_url: disableTriggerMessagingUsingGET_RAW_URL
 * @param id - id
 */
export const disableTriggerMessagingUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-messaging/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const disableTriggerMessagingUsingGET_RAW_URL = function() {
  return '/api/trigger-messaging/{id}/disable'
}
export const disableTriggerMessagingUsingGET_TYPE = function() {
  return 'get'
}
export const disableTriggerMessagingUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-messaging/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * enableTriggerMessaging
 * request: enableTriggerMessagingUsingGET
 * url: enableTriggerMessagingUsingGETURL
 * method: enableTriggerMessagingUsingGET_TYPE
 * raw_url: enableTriggerMessagingUsingGET_RAW_URL
 * @param id - id
 */
export const enableTriggerMessagingUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-messaging/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const enableTriggerMessagingUsingGET_RAW_URL = function() {
  return '/api/trigger-messaging/{id}/enable'
}
export const enableTriggerMessagingUsingGET_TYPE = function() {
  return 'get'
}
export const enableTriggerMessagingUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-messaging/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateTriggerMessagingRoles
 * request: updateTriggerMessagingRolesUsingPUT
 * url: updateTriggerMessagingRolesUsingPUTURL
 * method: updateTriggerMessagingRolesUsingPUT_TYPE
 * raw_url: updateTriggerMessagingRolesUsingPUT_RAW_URL
 * @param id - id
 * @param resourceRolesRequest - resourceRolesRequest
 */
export const updateTriggerMessagingRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-messaging/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['resourceRolesRequest'] !== undefined) {
    body = parameters['resourceRolesRequest']
  }
  if (parameters['resourceRolesRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: resourceRolesRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTriggerMessagingRolesUsingPUT_RAW_URL = function() {
  return '/api/trigger-messaging/{id}/roles'
}
export const updateTriggerMessagingRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTriggerMessagingRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-messaging/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTriggerRests
 * request: getTriggerRestsUsingGET
 * url: getTriggerRestsUsingGETURL
 * method: getTriggerRestsUsingGET_TYPE
 * raw_url: getTriggerRestsUsingGET_RAW_URL
 * @param method - 
 * @param name - 
 * @param orderDirection - 
 * @param orderField - 
 * @param page - 
 * @param processId - 
 * @param size - 
 * @param status - 
 * @param url - 
 */
export const getTriggerRestsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-rest'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['method'] !== undefined) {
    queryParameters['method'] = parameters['method']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['url'] !== undefined) {
    queryParameters['url'] = parameters['url']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTriggerRestsUsingGET_RAW_URL = function() {
  return '/api/trigger-rest'
}
export const getTriggerRestsUsingGET_TYPE = function() {
  return 'get'
}
export const getTriggerRestsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-rest'
  if (parameters['method'] !== undefined) {
    queryParameters['method'] = parameters['method']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['url'] !== undefined) {
    queryParameters['url'] = parameters['url']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createTriggerRest
 * request: createTriggerRestUsingPOST
 * url: createTriggerRestUsingPOSTURL
 * method: createTriggerRestUsingPOST_TYPE
 * raw_url: createTriggerRestUsingPOST_RAW_URL
 * @param triggerRestRequest - triggerRestRequest
 */
export const createTriggerRestUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-rest'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['triggerRestRequest'] !== undefined) {
    body = parameters['triggerRestRequest']
  }
  if (parameters['triggerRestRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: triggerRestRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createTriggerRestUsingPOST_RAW_URL = function() {
  return '/api/trigger-rest'
}
export const createTriggerRestUsingPOST_TYPE = function() {
  return 'post'
}
export const createTriggerRestUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-rest'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * triggerRestCheck
 * request: triggerRestCheckUsingGET
 * url: triggerRestCheckUsingGETURL
 * method: triggerRestCheckUsingGET_TYPE
 * raw_url: triggerRestCheckUsingGET_RAW_URL
 * @param method - 
 * @param url - 
 */
export const triggerRestCheckUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-rest/check'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['method'] !== undefined) {
    queryParameters['method'] = parameters['method']
  }
  if (parameters['method'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: method'))
  }
  if (parameters['url'] !== undefined) {
    queryParameters['url'] = parameters['url']
  }
  if (parameters['url'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: url'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const triggerRestCheckUsingGET_RAW_URL = function() {
  return '/api/trigger-rest/check'
}
export const triggerRestCheckUsingGET_TYPE = function() {
  return 'get'
}
export const triggerRestCheckUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-rest/check'
  if (parameters['method'] !== undefined) {
    queryParameters['method'] = parameters['method']
  }
  if (parameters['url'] !== undefined) {
    queryParameters['url'] = parameters['url']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTriggerRestById
 * request: getTriggerRestByIdUsingGET
 * url: getTriggerRestByIdUsingGETURL
 * method: getTriggerRestByIdUsingGET_TYPE
 * raw_url: getTriggerRestByIdUsingGET_RAW_URL
 * @param id - id
 */
export const getTriggerRestByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-rest/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTriggerRestByIdUsingGET_RAW_URL = function() {
  return '/api/trigger-rest/{id}'
}
export const getTriggerRestByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getTriggerRestByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-rest/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateTriggerRest
 * request: updateTriggerRestUsingPUT
 * url: updateTriggerRestUsingPUTURL
 * method: updateTriggerRestUsingPUT_TYPE
 * raw_url: updateTriggerRestUsingPUT_RAW_URL
 * @param id - id
 * @param triggerRestRequest - triggerRestRequest
 */
export const updateTriggerRestUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-rest/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['triggerRestRequest'] !== undefined) {
    body = parameters['triggerRestRequest']
  }
  if (parameters['triggerRestRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: triggerRestRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTriggerRestUsingPUT_RAW_URL = function() {
  return '/api/trigger-rest/{id}'
}
export const updateTriggerRestUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTriggerRestUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-rest/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteRestTrigger
 * request: deleteRestTriggerUsingDELETE
 * url: deleteRestTriggerUsingDELETEURL
 * method: deleteRestTriggerUsingDELETE_TYPE
 * raw_url: deleteRestTriggerUsingDELETE_RAW_URL
 * @param force - 
 * @param id - id
 */
export const deleteRestTriggerUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-rest/{id}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteRestTriggerUsingDELETE_RAW_URL = function() {
  return '/api/trigger-rest/{id}'
}
export const deleteRestTriggerUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteRestTriggerUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-rest/{id}'
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * disableTriggerRest
 * request: disableTriggerRestUsingGET
 * url: disableTriggerRestUsingGETURL
 * method: disableTriggerRestUsingGET_TYPE
 * raw_url: disableTriggerRestUsingGET_RAW_URL
 * @param id - id
 */
export const disableTriggerRestUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-rest/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const disableTriggerRestUsingGET_RAW_URL = function() {
  return '/api/trigger-rest/{id}/disable'
}
export const disableTriggerRestUsingGET_TYPE = function() {
  return 'get'
}
export const disableTriggerRestUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-rest/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * enableTriggerRest
 * request: enableTriggerRestUsingGET
 * url: enableTriggerRestUsingGETURL
 * method: enableTriggerRestUsingGET_TYPE
 * raw_url: enableTriggerRestUsingGET_RAW_URL
 * @param id - id
 */
export const enableTriggerRestUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-rest/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const enableTriggerRestUsingGET_RAW_URL = function() {
  return '/api/trigger-rest/{id}/enable'
}
export const enableTriggerRestUsingGET_TYPE = function() {
  return 'get'
}
export const enableTriggerRestUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-rest/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateTriggerRestRoles
 * request: updateTriggerRestRolesUsingPUT
 * url: updateTriggerRestRolesUsingPUTURL
 * method: updateTriggerRestRolesUsingPUT_TYPE
 * raw_url: updateTriggerRestRolesUsingPUT_RAW_URL
 * @param id - id
 * @param resourceRolesRequest - resourceRolesRequest
 */
export const updateTriggerRestRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/trigger-rest/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['resourceRolesRequest'] !== undefined) {
    body = parameters['resourceRolesRequest']
  }
  if (parameters['resourceRolesRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: resourceRolesRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTriggerRestRolesUsingPUT_RAW_URL = function() {
  return '/api/trigger-rest/{id}/roles'
}
export const updateTriggerRestRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTriggerRestRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/trigger-rest/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUsers
 * request: getUsersUsingGET
 * url: getUsersUsingGETURL
 * method: getUsersUsingGET_TYPE
 * raw_url: getUsersUsingGET_RAW_URL
 * @param name - 
 * @param orderDirection - 
 * @param orderField - 
 * @param page - 
 * @param size - 
 * @param status - 
 */
export const getUsersUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUsersUsingGET_RAW_URL = function() {
  return '/api/user'
}
export const getUsersUsingGET_TYPE = function() {
  return 'get'
}
export const getUsersUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/user'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createUser
 * request: createUserUsingPOST
 * url: createUserUsingPOSTURL
 * method: createUserUsingPOST_TYPE
 * raw_url: createUserUsingPOST_RAW_URL
 * @param request - request
 */
export const createUserUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createUserUsingPOST_RAW_URL = function() {
  return '/api/user'
}
export const createUserUsingPOST_TYPE = function() {
  return 'post'
}
export const createUserUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllUserSettings
 * request: getAllUserSettingsUsingGET
 * url: getAllUserSettingsUsingGETURL
 * method: getAllUserSettingsUsingGET_TYPE
 * raw_url: getAllUserSettingsUsingGET_RAW_URL
 */
export const getAllUserSettingsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/user-setting'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllUserSettingsUsingGET_RAW_URL = function() {
  return '/api/user-setting'
}
export const getAllUserSettingsUsingGET_TYPE = function() {
  return 'get'
}
export const getAllUserSettingsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/user-setting'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * saveUserSettings
 * request: saveUserSettingsUsingPOST
 * url: saveUserSettingsUsingPOSTURL
 * method: saveUserSettingsUsingPOST_TYPE
 * raw_url: saveUserSettingsUsingPOST_RAW_URL
 * @param settings - settings
 */
export const saveUserSettingsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/user-setting'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['settings'] !== undefined) {
    body = parameters['settings']
  }
  if (parameters['settings'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: settings'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const saveUserSettingsUsingPOST_RAW_URL = function() {
  return '/api/user-setting'
}
export const saveUserSettingsUsingPOST_TYPE = function() {
  return 'post'
}
export const saveUserSettingsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/user-setting'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUserSetting
 * request: getUserSettingUsingGET
 * url: getUserSettingUsingGETURL
 * method: getUserSettingUsingGET_TYPE
 * raw_url: getUserSettingUsingGET_RAW_URL
 * @param key - key
 */
export const getUserSettingUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/user-setting/{key}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{key}', `${parameters['key']}`)
  if (parameters['key'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: key'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserSettingUsingGET_RAW_URL = function() {
  return '/api/user-setting/{key}'
}
export const getUserSettingUsingGET_TYPE = function() {
  return 'get'
}
export const getUserSettingUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/user-setting/{key}'
  path = path.replace('{key}', `${parameters['key']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUserById
 * request: getUserByIdUsingGET
 * url: getUserByIdUsingGETURL
 * method: getUserByIdUsingGET_TYPE
 * raw_url: getUserByIdUsingGET_RAW_URL
 * @param id - id
 */
export const getUserByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/user/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserByIdUsingGET_RAW_URL = function() {
  return '/api/user/{id}'
}
export const getUserByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getUserByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/user/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateUser
 * request: updateUserUsingPUT
 * url: updateUserUsingPUTURL
 * method: updateUserUsingPUT_TYPE
 * raw_url: updateUserUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateUserUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/user/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateUserUsingPUT_RAW_URL = function() {
  return '/api/user/{id}'
}
export const updateUserUsingPUT_TYPE = function() {
  return 'put'
}
export const updateUserUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/user/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteUser
 * request: deleteUserUsingDELETE
 * url: deleteUserUsingDELETEURL
 * method: deleteUserUsingDELETE_TYPE
 * raw_url: deleteUserUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteUserUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/user/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteUserUsingDELETE_RAW_URL = function() {
  return '/api/user/{id}'
}
export const deleteUserUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteUserUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/user/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * disableUser
 * request: disableUserUsingGET
 * url: disableUserUsingGETURL
 * method: disableUserUsingGET_TYPE
 * raw_url: disableUserUsingGET_RAW_URL
 * @param id - id
 */
export const disableUserUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/user/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const disableUserUsingGET_RAW_URL = function() {
  return '/api/user/{id}/disable'
}
export const disableUserUsingGET_TYPE = function() {
  return 'get'
}
export const disableUserUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/user/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * enableUser
 * request: enableUserUsingGET
 * url: enableUserUsingGETURL
 * method: enableUserUsingGET_TYPE
 * raw_url: enableUserUsingGET_RAW_URL
 * @param id - id
 */
export const enableUserUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/user/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const enableUserUsingGET_RAW_URL = function() {
  return '/api/user/{id}/enable'
}
export const enableUserUsingGET_TYPE = function() {
  return 'get'
}
export const enableUserUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/user/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * generateApiKey
 * request: generateApiKeyUsingPUT
 * url: generateApiKeyUsingPUTURL
 * method: generateApiKeyUsingPUT_TYPE
 * raw_url: generateApiKeyUsingPUT_RAW_URL
 * @param id - id
 */
export const generateApiKeyUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/user/{id}/generate-api-key'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const generateApiKeyUsingPUT_RAW_URL = function() {
  return '/api/user/{id}/generate-api-key'
}
export const generateApiKeyUsingPUT_TYPE = function() {
  return 'put'
}
export const generateApiKeyUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/user/{id}/generate-api-key'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUserName
 * request: getUserNameUsingGET
 * url: getUserNameUsingGETURL
 * method: getUserNameUsingGET_TYPE
 * raw_url: getUserNameUsingGET_RAW_URL
 * @param id - id
 */
export const getUserNameUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/user/{id}/name'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserNameUsingGET_RAW_URL = function() {
  return '/api/user/{id}/name'
}
export const getUserNameUsingGET_TYPE = function() {
  return 'get'
}
export const getUserNameUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/user/{id}/name'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * resendRegisterToken
 * request: resendRegisterTokenUsingPOST
 * url: resendRegisterTokenUsingPOSTURL
 * method: resendRegisterTokenUsingPOST_TYPE
 * raw_url: resendRegisterTokenUsingPOST_RAW_URL
 * @param id - id
 */
export const resendRegisterTokenUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/user/{id}/resend-register-token'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const resendRegisterTokenUsingPOST_RAW_URL = function() {
  return '/api/user/{id}/resend-register-token'
}
export const resendRegisterTokenUsingPOST_TYPE = function() {
  return 'post'
}
export const resendRegisterTokenUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/user/{id}/resend-register-token'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getValidationRules
 * request: getValidationRulesUsingGET
 * url: getValidationRulesUsingGETURL
 * method: getValidationRulesUsingGET_TYPE
 * raw_url: getValidationRulesUsingGET_RAW_URL
 * @param name - 
 * @param orderDirection - 
 * @param orderField - 
 * @param page - 
 * @param size - 
 */
export const getValidationRulesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/validation-rule'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getValidationRulesUsingGET_RAW_URL = function() {
  return '/api/validation-rule'
}
export const getValidationRulesUsingGET_TYPE = function() {
  return 'get'
}
export const getValidationRulesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/validation-rule'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createValidationRule
 * request: createValidationRuleUsingPOST
 * url: createValidationRuleUsingPOSTURL
 * method: createValidationRuleUsingPOST_TYPE
 * raw_url: createValidationRuleUsingPOST_RAW_URL
 * @param request - request
 */
export const createValidationRuleUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/validation-rule'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createValidationRuleUsingPOST_RAW_URL = function() {
  return '/api/validation-rule'
}
export const createValidationRuleUsingPOST_TYPE = function() {
  return 'post'
}
export const createValidationRuleUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/validation-rule'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getValidationRuleById
 * request: getValidationRuleByIdUsingGET
 * url: getValidationRuleByIdUsingGETURL
 * method: getValidationRuleByIdUsingGET_TYPE
 * raw_url: getValidationRuleByIdUsingGET_RAW_URL
 * @param id - id
 */
export const getValidationRuleByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/validation-rule/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getValidationRuleByIdUsingGET_RAW_URL = function() {
  return '/api/validation-rule/{id}'
}
export const getValidationRuleByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getValidationRuleByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/validation-rule/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateValidationRule
 * request: updateValidationRuleUsingPUT
 * url: updateValidationRuleUsingPUTURL
 * method: updateValidationRuleUsingPUT_TYPE
 * raw_url: updateValidationRuleUsingPUT_RAW_URL
 * @param id - id
 * @param request - request
 */
export const updateValidationRuleUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/validation-rule/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateValidationRuleUsingPUT_RAW_URL = function() {
  return '/api/validation-rule/{id}'
}
export const updateValidationRuleUsingPUT_TYPE = function() {
  return 'put'
}
export const updateValidationRuleUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/validation-rule/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteValidationRule
 * request: deleteValidationRuleUsingDELETE
 * url: deleteValidationRuleUsingDELETEURL
 * method: deleteValidationRuleUsingDELETE_TYPE
 * raw_url: deleteValidationRuleUsingDELETE_RAW_URL
 * @param force - 
 * @param id - id
 */
export const deleteValidationRuleUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/validation-rule/{id}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteValidationRuleUsingDELETE_RAW_URL = function() {
  return '/api/validation-rule/{id}'
}
export const deleteValidationRuleUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteValidationRuleUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/validation-rule/{id}'
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateValidationRuleRoles
 * request: updateValidationRuleRolesUsingPUT
 * url: updateValidationRuleRolesUsingPUTURL
 * method: updateValidationRuleRolesUsingPUT_TYPE
 * raw_url: updateValidationRuleRolesUsingPUT_RAW_URL
 * @param id - id
 * @param resourceRolesRequest - resourceRolesRequest
 */
export const updateValidationRuleRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/validation-rule/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['resourceRolesRequest'] !== undefined) {
    body = parameters['resourceRolesRequest']
  }
  if (parameters['resourceRolesRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: resourceRolesRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateValidationRuleRolesUsingPUT_RAW_URL = function() {
  return '/api/validation-rule/{id}/roles'
}
export const updateValidationRuleRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateValidationRuleRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/validation-rule/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}