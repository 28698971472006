import Vuetify from '../../plugins/vuetify'

export default {
  /**
   * Theme and Layout
   */
  globalTheme: (state, theme) => {
    state.globalTheme = theme
  },
  setRTL: (state, isRTL) => {
    Vuetify.framework.rtl = isRTL
    state.isRTL = isRTL
  },
  selectedLang(state, data) {
    state.selectedLang = data
  },
  isLoggedIn(state, data) {
    state.isLoggedIn = data
  },
  defaultFrontend(state, data) {
    state.defaultFrontend = data
  },
  savedLocations(state, data) {
    state.savedLocations = data
  },
  userSettings(state, data) {
    state.userSettings = data
  }
}
