module.exports = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  JWT: 'JWT',
  MONGODB: 'Mongo DB',
  JDBC: 'JDBC',
  SMTP: 'SMTP',
  TWILIO: 'Twilio',
  OAUTH: 'OAuth',
  OAUTH2_REFRESH_TOKEN: 'OAuth',
  SLACK: 'Slack',
  DELETE: 'Delete',
  GET: 'Get',
  PATCH: 'Patch',
  POST: 'Post',
  PUT: 'Put',
  SUCCESSFUL: 'Successful',
  UNSUCCESSFUL: 'Unsuccessful',
  FAILED: 'Failed',
  IN_PROGRESS: 'In progress',
  NEW: 'New',
  ON_HOLD: 'On hold',
  CRON: 'Cron',
  REST: 'Rest',
  RUN_PROCESS: 'Run process',
  EMAIL: 'Smtp',
  EXECUTE_PROCESS: 'Execute process',
  FOREACH: 'For Each',
  GROOVY: 'Groovy',
  JS: 'JavaScript',
  KOTLIN: 'Kotlin',
  SWITCH: 'Switch',
  WHILE: 'While',
  EDIT: 'Edit',
  VIEW: 'View',
  USE: 'Use',
  PROCESS_INACTIVE: 'Process Inactive',
  FRONTEND: 'Frontend',
  INSTANCE: 'Instance',
  PASTE: 'Paste from memory',
  CREATE: 'Create',
  YES: 'Yes',
  NO: 'No',
  GLOBAL_TRANSLATION: 'Translation',
  MODULE: 'Module',
  PROCESS: 'Process',
  PROCESS_CREDENTIAL: 'Credential',
  SETTING: 'Setting',
  TEXT_TEMPLATE: 'Text Template',
  TEMPLATE: 'Template',
  TRIGGER_CRON: 'Trigger Cron',
  TRIGGER_REST: 'Trigger Rest',
  NUMBER: 'Number',
  STRING: 'String',
  MAX_LENGTH: 'Max Length',
  MAX_VALUE: 'Max Value',
  MIN_LENGTH: 'Min Length',
  MIN_VALUE: 'Min Value',
  BODY: 'Body',
  HEADERS: 'Headers',
  QUERY_PARAMS: 'Query Params',
  VALIDATION_RULE: 'Validation Rule',
  FLOWY: 'Flowy',
  BOOLEAN: 'Boolean',
  TIMESTAMP: 'Timestamp',
  INTEGER: 'Integer',
  FLOAT: 'Float',
  PROCESS_SETTING: 'Process Settings',
  CHECK_EMAIL_AVAILABILITY: 'Check Email Availability',
  CREATE_USER: 'Create User',
  GENERATE_API_KEY: 'Generate API Key',
  VALIDATE_API_KEY: 'Validate API Key',
  USER: 'User',
  FILE_JSON: 'File JSON',
  FILE_XML: 'File XML',
  PLUGIN: 'Plugin',
  AWS: 'AWS',
  S3: 'S3',
  LIST: 'List',
  READ: 'Read',
  UNSET_VARIABLES: 'Unset Variables',
  SECURITY: 'Security',
  DECRYPT: 'Decrypt',
  ENCRYPT: 'Encrypt',
  HANDLEBARS: 'Handlebars',
  MUSTACHE: 'Mustache',
  THYMELEAF: 'Thymeleaf',
  VELOCITY: 'Velocity',
  SCRIPT: 'Script',
  IMAP: 'Imap',
  TIMEOUT: 'Timeout',
  MARK_READ: 'Mark Read',
  MARK_UNREAD: 'Mark Unread',
  SEARCH: 'Search',
  CUSTOM: 'Custom',
  PATH_VARIABLES: 'Path variables',
  PDF: 'PDF',
  FINISHED: 'Finished',
  CLEAN_UP: 'Clean Up',
  FIX_EXECUTION_STATE: 'Fix Execution State',
  MAX_SIZE: 'Max Size in bytes',
  PROCESS_EVENTS: 'Process events',
  ARRAY: 'Array',
  DOUBLE: 'Double',
  MIN_SIZE: 'Min size in bytes',
  MAX_SIZE_ARRAY: 'Max. amount of records',
  MIN_SIZE_ARRAY: 'Min. amount of records',
  VALIDATE_PASSWORD: 'Validate password',
  GET_USER_DETAILS: 'Get user details',
  GET_USER_ROLES: 'Get user roles',
  CREDENTIALS_SYNC: 'Credentials sync',
  CRON_TRIGGERS_SYNC: 'Cron triggers sync',
  INSTANCE_HEARTBEAT: 'Instance heartbeat',
  PLUGINS_SYNC: 'Plugins sync',
  REST_TRIGGER_SYNC: 'Rest trigger sync',
  SETTINGS_SYNC: 'Settings sync',
  VALIDATION: 'Validation Rule',
  OBJECT: 'Object',
  LONG: 'Long',
  ATTACHMENTS: 'Get attachments',
  CSV: 'CSV file',
  CSV_TO_JSON: 'CSV to JSON',
  JSON_TO_CSV: 'JSON to CSV',
  CONVERT: 'Convert',
  DOWNLOAD: 'Download',
  INFO: 'Info',
  RESCALE: 'Rescale',
  IMAGE: 'Image',
  FROM_TEMPLATE: 'From template',
  EXECUTE_EXTERNAL_COMMAND: 'Execute external command',
  TRY_CATCH: 'Try Catch',
  KAFKA: 'Kafka',
  MESSAGING: 'Messaging',
  TRIGGER_MESSAGING: 'Trigger Messaging',
  INSTANCE_ADMIN: 'Instance Admin',
  INSTANCE_PROCESSING: 'Instance Processing',
  PROCESSING: 'Processing',
  ADMIN: 'Admin',
  PAYMENT_SENSE: 'Payment Sense',
  PAYMENT_SENSE_PAC: 'Paymentsense PAC',
  CANCEL_TRANSACTION: 'Cancel Transaction',
  GET_REPORT: 'Get Report',
  GET_TERMINAL: 'Get Terminal',
  GET_TERMINALS: 'Get Terminals',
  GET_TRANSACTION: 'Get Transaction',
  SIGNATURE: 'Signature',
  START_REPORT: 'Start Report',
  START_TRANSACTION: 'Start Transaction',
  BANKING: 'Banking',
  END_OF_DAY: 'End of Day',
  X_BALANCE: 'X Balance',
  Z_BALANCE: 'Z Balance',
  ACCOUNT_VERIFICATION: 'Account Verification',
  COMPLETION: 'Completion',
  DUPLICATE: 'Duplicate',
  PRE_AUTH: 'Pre Auth',
  REFUND: 'Refund',
  SALE: 'Sale',
  JMS: 'JMS',
  CLEAN_CAPTCHA: 'Clean captcha',
  MESSAGING_TRIGGER_SYNC: 'Messaging trigger sync',
  PAYMENT_SENSE_CONNECT_E: 'Paymentsense Connect E',
  ACCESS_TOKEN: 'Access Token',
  CROSS_REFERENCE_PAYMENT: 'Cross Reference Payment',
  PAYMENT_DETAILS: 'Payment Details',
  PAYMENT_METHODS: 'Payment Methods',
  RESUME_PAYMENT: 'Resume Payment',
  REVOKE_ACCESS_TOKEN: 'Revoke Access Token',
  GET_USERS_BY_ROLES: 'Get users by roles',
  UUID: 'UUID',
  CREDENTIAL: 'Credential',
  UPDATE: 'Update',
  ENABLE: 'Enable',
  DISABLE: 'Disable',
  QUERY_BUILDER: 'Query Builder',
  REGEX: 'Regex',
  RABBITMQ: 'RabbitMQ',
  AWS_SQS: 'AWS SQS',
  ZIP: 'Zip',
  COMPRESS: 'Compress',
  DECOMPRESS: 'Decompress',
  CLEAN_UP_ERRORS: 'Clean up errors',
  CLEAN_HISTORY: 'Clean history',
  LOG: 'Log',
  ROLE: 'Permission: set roles',
  ROLES: 'Roles',
  ENCODER: 'Encoder',
  ENCODE_HTML_SPECIAL_CHARS: 'Encode HTML special chars',
  URL_DECODE: 'URL decode',
  URL_ENCODE: 'URL encode',
  LIBRARY: 'Library',
  LIBRARIES: 'Libraries',
  ASYNC: 'Asynchronous',
  SYNC: 'Synchronous',
  INLINE: 'Inline',
  STORAGE: 'Storage',
  UPSERT: 'Upsert',
  CLEAN_STORAGE: 'Storage Clean Up',
  CAUGHT: 'Caught',
  FORMATTING: 'Formatting',
  DECIMAL_FORMAT: 'Decimal Format'
}
