<template>
  <v-menu offset-y left transition="slide-y-transition">
    <template v-slot:activator="{ on }">
      <v-btn icon class="elevation-0" v-on="on">
        <v-avatar size="40" data-cy="profile-icon">
          <v-icon large :color="$vuetify.theme.dark ? 'accent' : 'primary'">mdi-account</v-icon>
        </v-avatar>
      </v-btn>
    </template>

    <!-- user menu list -->
    <v-list dense nav>
      <v-list-item
        v-for="(item, index) in menu"
        :key="index"
        :exact="item.exact"
        :disabled="item.disabled"
        link
        :to="{ name: item.value }"
      >
        <v-list-item-icon data-cy="profile-item">
          <v-icon small :class="{ 'grey--text': item.disabled }">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-1"></v-divider>

      <v-list-item
        v-for="(item, index) in themes"
        :key="index + 1000"
        :disabled="item.disabled"
        @click="$emit('changeTheme', item.value)"
      >
        <v-list-item-icon>
          <v-icon :class="{ 'primary--text': $vuetify.theme.dark === item.value }">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title :class="{ 'primary--text': $vuetify.theme.dark === item.value }">{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-1"></v-divider>

      <v-list-item link @click="logout()">
        <v-list-item-icon>
          <v-icon small>mdi-logout-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import auth from '@/auth/index'
/*
|---------------------------------------------------------------------
| Toolbar User Component
|---------------------------------------------------------------------
|
| Quickmenu for user menu shortcuts on the toolbar
|
*/
export default {
  data() {
    return {
      themes: [
        { icon: 'mdi-weather-sunny', text: 'Light', value: false },
        { icon: 'mdi-moon-waning-crescent', text: 'Dark', value: true }
      ],
      menu: [
        { icon: 'mdi-account-box-outline', text: 'Profile', value: 'profile', exact: true }
      ]
    }
  },
  methods: {
    logout() {
      auth.logout(true)
    }
  }
}
</script>
<style lang="scss" scoped>
.selected-colors {

}
</style>
