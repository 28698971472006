export default {
  userData: {
    loggedInStatus: false,
    token: ''
  },

  // Removes userData
  logout(redirection) {
    localStorage.removeItem('userData')
    localStorage.removeItem('preFillData')
    localStorage.removeItem('allUsers')
    localStorage.removeItem('copiedTemplateData')
    localStorage.removeItem('copiedTemplateTranslation')
    localStorage.removeItem('copiedData')
    this.userData.loggedInStatus = false
    this.userData.token = ''
    if (redirection) window.open(`/${localStorage.selectedLanguage || 'en'}/login?redirect=${window.location.pathname}`, '_self')
  },

  fetchRoles() {
    try {
      const user = JSON.parse(localStorage.userData)
      const { roles } = user
      const err = {}

      if (!roles || roles.length === 0) throw err

      return roles
    } catch (err) {
      // this.logout()

      return null
    }
  },

  // Check if userData exists
  isLoggedIn() {
    const id = localStorage.userData ? JSON.parse(localStorage.userData).authToken : false

    this.userData.loggedInStatus = !!id

    return this.userData.loggedInStatus
  },

  getToken() {

    if (this.userData.token) return this.userData.token

    const id = localStorage.userData ? JSON.parse(localStorage.userData).authToken : false

    if (id) return id

    return false
  }
}
