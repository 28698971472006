import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/auth/index'

Vue.use(Router)

const lang = localStorage.selectedLanguage || 'en'

export const routes = [
  {
    path: '/',
    redirect: `/${lang}`
  },
  {
    path: '/set-password/:token',
    redirect: `/${lang}/set-password/:token`
  },
  {
    path: '/:lang',
    component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout'),
    children: [
      {
        path: 'login',
        name: 'login',
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "login" */ '@/pages/login/Login')
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "forgot-password" */ '@/pages/login/ForgotPassword')
      },
      {
        path: 'set-password/:token',
        name: 'set-password',
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "set-password" */ '@/pages/SetPassword')
      },
      {
        path: '',
        name: 'home',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "home" */ '@/pages/Home')
      },
      {
        path: 'profile',
        name: 'profile',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "profile" */ '@/pages/profile/Profile')
      },
      {
        path: 'processes',
        component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
        children: [{
          path: '',
          name: 'processes',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "processes" */ '@/pages/processes/Processes')
        }, {
          path: 'create',
          name: 'processCreate',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "process-create" */ '@/pages/processes/ProcessDetails')
        }, {
          path: 'edit/:id',
          name: 'processEdit',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "process-edit" */ '@/pages/processes/ProcessDetails')
        }]
      },
      {
        path: 'credentials',
        component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
        children: [{
          path: '',
          name: 'credentials',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "credentials" */ '@/pages/credentials/Credentials')
        }, {
          path: 'create/:type',
          name: 'credentialCreate',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "credential-create" */ '@/pages/credentials/CredentialDetails')
        }, {
          path: 'edit/:id',
          name: 'credentialEdit',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "credential-edit" */ '@/pages/credentials/CredentialDetails')
        }]
      },
      {
        path: 'triggers',
        component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
        children: [{
          path: 'cron',
          component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
          children: [{
            path: '',
            name: 'cron',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "crons" */ '@/pages/cron/Crons')
          }, {
            path: 'create',
            name: 'cronCreate',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "cron-create" */ '@/pages/cron/CronDetails')
          }, {
            path: 'edit/:id',
            name: 'cronEdit',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "cron-edit" */ '@/pages/cron/CronDetails')
          }]
        },
        {
          path: 'rest',
          component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
          children: [{
            path: '',
            name: 'rest',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "rests" */ '@/pages/rest/Rests')
          }, {
            path: 'create',
            name: 'restCreate',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "rest-create" */ '@/pages/rest/RestDetails')
          }, {
            path: 'edit/:id',
            name: 'restEdit',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "rest-edit" */ '@/pages/rest/RestDetails')
          }]
        },
        {
          path: 'messaging',
          component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
          children: [{
            path: '',
            name: 'messaging',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "messagings" */ '@/pages/trigger-messaging/Messaging')
          }, {
            path: 'create',
            name: 'messagingCreate',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "messaging-create" */ '@/pages/trigger-messaging/MessagingDetails')
          }, {
            path: 'edit/:id',
            name: 'messagingEdit',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "messaging-edit" */ '@/pages/trigger-messaging/MessagingDetails')
          }]
        }]
      },
      {
        path: 'logs-processes',
        component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
        children: [{
          path: '',
          name: 'logs-processes',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "logs-processes" */ '@/pages/logs-processes/LogsProcesses')
        }]
      },
      {
        path: 'logs-steps',
        component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
        children: [{
          path: '',
          name: 'logs-steps',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "logs-steps" */ '@/pages/logs-steps/LogsSteps')
        }]
      },
      {
        path: 'logs-events',
        component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
        children: [{
          path: '',
          name: 'logs-events',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "logs-events" */ '@/pages/logs-events/LogsEvents')
        }]
      },
      {
        path: 'logs-internal-jobs',
        component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
        children: [{
          path: '',
          name: 'logs-internal-jobs',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "logs-internal-jobs" */ '@/pages/logs-internal-jobs/LogsInternalJobs')
        }]
      },
      {
        path: 'logs-telemetry',
        component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
        children: [{
          path: '',
          name: 'logs-telemetry',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "logs-telemetry" */ '@/pages/logs-telemetry/Telemetries')
        }]
      },
      {
        path: 'logs-instances',
        component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
        children: [{
          path: '',
          name: 'logs-instances',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "instances" */ '@/pages/logs-instances/LogsInstances')
        }]
      },
      {
        path: 'permissions',
        component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
        children: [{
          path: 'users',
          component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
          children: [{
            path: '',
            name: 'users',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "users" */ '@/pages/users/Users')
          }, {
            path: 'create',
            name: 'userCreate',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "user-create" */ '@/pages/users/UserDetails')
          }, {
            path: 'edit/:id',
            name: 'userEdit',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "user-edit" */ '@/pages/users/UserDetails')
          }]
        },
        {
          path: 'roles',
          component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
          children: [{
            path: '',
            name: 'roles',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "roles" */ '@/pages/roles/Roles')
          }, {
            path: 'create',
            name: 'roleCreate',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "role-create" */ '@/pages/roles/RoleDetails')
          }, {
            path: 'edit/:id',
            name: 'roleEdit',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "role-edit" */ '@/pages/roles/RoleDetails')
          }]
        }]
      },
      {
        path: 'templates',
        component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
        children: [{
          path: 'templates',
          component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
          children: [{
            path: '',
            name: 'templates',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "templates" */ '@/pages/templates/Templates')
          }, {
            path: 'create',
            name: 'templateCreate',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "template-create" */ '@/pages/templates/TemplateDetails')
          }, {
            path: 'edit/:id',
            name: 'templateEdit',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "templates-edit" */ '@/pages/templates/TemplateDetails')
          }]
        },
        {
          path: 'translations',
          component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
          children: [{
            path: '',
            name: 'translations',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "translations" */ '@/pages/translations/Translations')
          }, {
            path: 'create',
            name: 'translationCreate',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "translation-create" */ '@/pages/translations/TranslationDetails')
          }, {
            path: 'edit/:id',
            name: 'translationEdit',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "translation-edit" */ '@/pages/translations/TranslationDetails')
          }]
        }]
      },
      {
        path: 'settings',
        component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
        children: [{
          path: 'frontend',
          name: 'frontend',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "settings-frontend" */ '@/pages/settings/Settings')
        }, {
          path: 'instance-processing',
          name: 'instance-processing',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "settings-instance-processing" */ '@/pages/settings/Settings')
        }, {
          path: 'instance-settings',
          name: 'instance-settings',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "settings-instance-settings" */ '@/pages/settings/Settings')
        }, {
          path: 'process',
          name: 'process',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "settings-process" */ '@/pages/settings/Settings')
        }, {
          path: 'plugin',
          name: 'plugin',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "settings-plugin" */ '@/pages/settings/Settings')
        }, {
          path: 'create/:type',
          name: 'settingCreate',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "setting-create" */ '@/pages/settings/SettingDetails')
        }, {
          path: 'edit/:id',
          name: 'settingEdit',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "setting-edit" */ '@/pages/settings/SettingDetails')
        }]
      },
      {
        path: 'validations',
        component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
        children: [{
          path: '',
          name: 'validations',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "validations" */ '@/pages/validation/Validations')
        }, {
          path: 'create',
          name: 'validationCreate',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "validation-create" */ '@/pages/validation/ValidationDetails')
        }, {
          path: 'edit/:id',
          name: 'validationEdit',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "validation-edit" */ '@/pages/validation/ValidationDetails')
        }]
      },
      {
        path: 'libraries',
        component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
        children: [{
          path: '',
          name: 'libraries',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "libraries" */ '@/pages/libraries/Libraries')
        }, {
          path: 'create',
          name: 'libraryCreate',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "library-create" */ '@/pages/libraries/LibraryDetails')
        }, {
          path: 'edit/:id',
          name: 'libraryEdit',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "library-edit" */ '@/pages/libraries/LibraryDetails')
        }]
      },
      {
        path: 'modules',
        component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
        children: [
          {
            path: 'local',
            component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
            children: [{
              path: '',
              name: 'local',
              meta: { requiresAuth: true },
              component: () => import(/* webpackChunkName: "modules" */ '@/pages/modules/Modules')
            }, {
              path: 'create',
              name: 'moduleCreate',
              meta: { requiresAuth: true },
              component: () => import(/* webpackChunkName: "module-create" */ '@/pages/modules/ModuleDetails')
            }, {
              path: 'edit/:id',
              name: 'moduleEdit',
              meta: { requiresAuth: true },
              component: () => import(/* webpackChunkName: "module-edit" */ '@/pages/modules/ModuleDetails')
            }, {
              path: 'import',
              name: 'moduleImport',
              meta: { requiresAuth: true },
              component: () => import(/* webpackChunkName: "module-edit" */ '@/pages/modules/ModuleImport')
            }]
          },
          {
            path: 'hub',
            component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
            children: [{
              path: '',
              name: 'hub',
              meta: { requiresAuth: true },
              component: () => import(/* webpackChunkName: "hub" */ '@/pages/hub/Hub')
            },
            {
              path: 'edit/:id',
              name: 'hubEdit',
              meta: { requiresAuth: true },
              component: () => import(/* webpackChunkName: "hub" */ '@/pages/hub/HubDetails.vue')
            }]
          }
        ]
      },
      {
        path: 'gdpr',
        component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
        children: [{
          path: '',
          name: 'gdpr',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "modules" */ '@/pages/gdpr/Gdpr')
        }]
      },
      {
        path: 'plugins',
        component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
        children: [{
          path: '',
          name: 'plugins',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "plugins" */ '@/pages/plugins/Plugins')
        }, {
          path: 'create',
          name: 'pluginCreate',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "plugin-create" */ '@/pages/plugins/PluginDetails')
        }, {
          path: 'edit/:id',
          name: 'pluginEdit',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "plugin-edit" */ '@/pages/plugins/PluginDetails')
        }]
      },
      {
        path: 'history',
        component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
        children: [{
          path: '',
          name: 'history',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "plugins" */ '@/pages/history/History')
        }, {
          path: 'edit/:id',
          name: 'historyEdit',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "plugin-edit" */ '@/pages/history/HistoryDetails')
        }]
      },
      {
        path: 'mass-changes',
        component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/EmptyLayout'),
        children: [{
          path: '',
          name: 'mass-changes',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "mass-changes" */ '@/pages/mass-change/MassChange')
        }]
      },
      {
        path: 'terms-and-Conditions',
        name: 'terms',
        component: () => import(/* webpackChunkName: "terms-and-Conditions" */ '@/pages/Protection-terms')
      },
      {
        path: 'data-protection-policy',
        name: 'data-protection',
        component: () => import(/* webpackChunkName: "data-protection-policy" */ '@/pages/Protection-terms')
      },
      {
        path: '*',
        component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/ErrorLayout'),
        children: [{
          path: '',
          name: 'error',
          component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFoundPage')
        }]
      }
    ]
  }]

const router = new Router({
  mode: 'history',
  base: '/',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  if (to?.matched?.some((record) => record?.meta?.requiresAuth)) {
    if (!auth.isLoggedIn()) {
      return next({
        path: `/${lang}/login`,
        query: { redirect: to.fullPath }
      })
    } else {

      return next()
    }
  } else {

    return next()
  }
})

/**
 * After each route update
 */
// router.afterEach((to, from) => {
// })

export default router
