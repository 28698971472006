<template>
  <div v-click-outside="hideSearch" class="overall-search">
    <v-text-field
      v-if="!searchField.length"
      v-model.trim="searchValue"
      outlined
      dense
      clearable
      hide-details
      :placeholder="$lang.labels.search"
      prepend-inner-icon="mdi-magnify"
      class="expanding-search"
      data-cy="global-search"
      :loading="loading"
      :class="{ closed: searchBoxClosed && !searchValue }"
      @keyup.enter="search"
      @focus="searchBoxClosed = false; show = !!result.length"
      @blur="searchBoxClosed = true"
      @click:clear="result = []; show = false"
    ></v-text-field>
    <v-text-field
      v-else
      v-model.trim="searchValue"
      velse
      outlined
      dense
      clearable
      hide-details
      :placeholder="'Templates'"
      prepend-inner-icon="mdi-magnify"
      class="expanding-search"
      data-cy="global-search"
      :loading="loading"
      @keyup.enter="searchOneField"
      @focus="show = !!result.length"
      @click:clear="result = []; show = false"
    ></v-text-field>
    <v-expand-transition v-if="show && !searchField">
      <v-list class="search-result">
        <v-list-item
          v-for="(item, i) in result"
          :key="i"
          class="result-item"
          :to="'/' + $route.params.lang + item.route + item.id"
          link
          @click="show = false"
        >
          <v-list-item-content>
            <v-list-item-title class="d-flex align-center">
              <v-icon small>{{ item.icon }}</v-icon>
              <div class="ml-1">{{ item.name }}</div>
            </v-list-item-title>
            <v-list-item-subtitle v-text="item.comment"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="(searchValue && searchValue.length >= 3 && !result.length) || !timer">
          <v-list-item-content class="no-search-result">
            No search result
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-expand-transition>
    <v-expand-transition v-if="show && searchField">
      <v-list class="search-result">
        <v-list-item

          v-for="(item, i) in result"
          :key="i"
          class="result-item"
          @click="add(item.name); searchValue = null"
        >
          <v-list-item-content>
            <v-list-item-title class="d-flex align-center">
              <v-icon small>{{ item.icon }}</v-icon>
              <div class="ml-1">{{ item.name }}</div>
            </v-list-item-title>
            <v-list-item-subtitle v-text="item.comment"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="(searchValue && searchValue.length >= 3 && !result.length) || !timer">
          <v-list-item-content class="no-search-result">
            No search result
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-expand-transition>
  </div>
</template>

<script>
import {
  getTriggerCronsUsingGET as getCrons,
  getTriggerMessagingsUsingGET as getMessagings,
  getTriggerRestsUsingGET as getRests,
  getProcessesUsingGET as getProcesses,
  getProcessCredentialsUsingGET as getCredentials,
  getTextTemplatesUsingGET as getTemplates,
  getTranslationsUsingGET as getTranslations,
  getValidationRulesUsingGET as getValidations,
  getPluginsUsingGET as getPlugins,
  getModulesUsingGET as getModules,
  getUsersUsingGET as getUsers,
  getRolesUsingGET as getRoles,
  getLibrariesUsingGET as getLibraries
} from '@/utils/api'

export default {
  props: {
    searchField: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      show: false,
      searchValue: '',
      searchBoxClosed: true,
      loading: false,
      timer: null,
      result: []
    }
  },
  watch: {
    searchValue: {
      handler() {
        if (!this.searchValue || this.clicked) {
          this.show = false
          this.result = []
        }
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          if (this.searchField) {
            this.searchOneField()
          } else {
            this.search()
          }
        }, 750)
      }
    }
  },
  methods: {
    add(name) {
      this.$emit('addTemplate', name )
      this.show = false
    },
    searchOneField() {

      this.result = []
      this.show = false
      this.loading = false
      if (!this.searchValue || this.searchValue.length < 3) {
        return
      }
      this.loading = true

      const arr = []

      if (this.searchField) {
        switch (this.searchField) {
        case 'getTemplates':

          // eslint-disable-next-line no-case-declarations
          const field = getTemplates({ name: this.searchValue })

          arr.push(field)

          break
        }

      }
      Promise.all(arr).then((data) => {
        data.map((item) => {
          // eslint-disable-next-line consistent-return,array-callback-return
          const result = item.data.data.items.filter((i) => {

            if (+i.id !== +this.$route.params.id) {
              const options = this.getModuleIcon(item.config.url)

              i.icon = options.icon
              i.route = options.url

              return i
            }
          })

          this.result.push(...result)

          return result
        })
      }).catch((error) => {
        console.log('error', error)
      }).finally(() => {
        this.show = true
        this.loading = false
      })
    },
    search() {
      this.result = []
      this.show = false
      this.loading = false
      if (!this.searchValue || this.searchValue.length < 3) {
        return
      }
      this.loading = true
      const crons =  getCrons({ name: this.searchValue })
      const messagings = getMessagings({ name: this.searchValue })
      const rests = getRests({ name: this.searchValue })
      const processes = getProcesses({ name: this.searchValue })
      const credentials = getCredentials({ name: this.searchValue })
      const templates = getTemplates({ name: this.searchValue })
      const translations = getTranslations({ name: this.searchValue })
      const validations = getValidations({ name: this.searchValue })
      const plugins = getPlugins({ name: this.searchValue })
      const modules = getModules({ name: this.searchValue })
      const users = getUsers({ name: this.searchValue })
      const roles = getRoles({ name: this.searchValue })
      const libraries = getLibraries({ name: this.searchValue })

      Promise.all([
        crons,
        messagings,
        rests,
        processes,
        credentials,
        templates,
        translations,
        validations,
        plugins,
        modules,
        users,
        roles,
        libraries
      ]).then((data) => {
        data.map((item) => {
          const result = item.data.data.items.map((i) => {
            const options = this.getModuleIcon(item.config.url)

            i.icon = options.icon
            i.route = options.url

            return i
          })

          this.result.push(...result)

          return result
        })
      }).catch((error) => {
        console.log('error', error)
      }).finally(() => {
        this.show = true
        this.loading = false
      })
    },
    getModuleIcon(type) {
      if (type.includes('trigger-cron')) {
        return {
          icon: 'mdi-briefcase-clock-outline',
          url: '/triggers/cron/edit/'
        }
      } else if (type.includes('trigger-messaging')) {
        return {
          icon: 'mdi-message-text-outline',
          url: '/triggers/messaging/edit/'
        }
      } else if (type.includes('trigger-rest')) {
        return {
          icon: 'mdi-web',
          url: '/triggers/rest/edit/'
        }
      } else if (type.includes('process-credential')) {
        return {
          icon: 'mdi-lock',
          url: '/credentials/edit/'
        }
      } else if (type.includes('process')) {
        return {
          icon: 'mdi-console-network',
          url: '/processes/edit/'
        }
      } else if (type.includes('text-template')) {
        return {
          icon: 'mdi-text-box-outline',
          url: '/templates/templates/edit/'
        }
      } else if (type.includes('translation')) {
        return {
          icon: 'mdi-text',
          url: '/templates/translations/edit/'
        }
      } else if (type.includes('validation-rule')) {
        return {
          icon: 'mdi-magnify-scan',
          url: '/validations/edit/'
        }
      } else if (type.includes('plugin')) {
        return {
          icon: 'mdi-puzzle-outline',
          url: '/plugins/edit/'
        }
      } else if (type.includes('module')) {
        return {
          icon: 'mdi-view-module-outline',
          url: '/modules/local/edit/'
        }
      } else if (type.includes('user')) {
        return {
          icon: 'mdi-account',
          url: '/permissions/users/edit/'
        }
      } else if (type.includes('role')) {
        return {
          icon: 'mdi-account-group-outline',
          url: '/permissions/roles/edit/'
        }
      } else if (type.includes('library')) {
        return {
          icon: 'mdi-library-outline',
          url: '/libraries/edit/'
        }
      } else {
        return ''
      }
    },
    hideSearch() {
      this.show = false
      this.searchBoxClosed = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .overall-search {

    .result-item {
      cursor: pointer;
    }

    .search-result {
      max-height: 350px;
      position: absolute;
      overflow-x: hidden;
      overflow-y: auto;
      margin-top: 5px;
      border: 2px solid var(--v-primary-base);
      border-radius: 5px;
      z-index: 1;
    }

    .no-search-result {
      display: flex;
      justify-content: center;
    }

    .expanding-search {
      transition: max-width 0.5s;
      margin-left: auto;
      margin-right: 0 !important;
      width: inherit;

      &.closed {
        max-width: 48px;
      }
    }
  }

  @media screen and (max-width: 1060px) {
    .overall-search {
      width: 300px;

      .search-result {
        width: 300px;
      }
    }
  }

  @media screen and (max-width: 890px) {
    .overall-search {
      display: none !important;
    }
  }
</style>
