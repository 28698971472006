<template>
  <v-menu offset-y left transition="slide-y-transition">
    <template v-slot:activator="{ on }">
      <v-btn icon class="elevation-0" v-on="on">

        <v-avatar size="40">
          <v-icon large :color="$vuetify.theme.dark ? 'accent' : 'primary'">mdi-earth</v-icon>
        </v-avatar>

      </v-btn>
    </template>

    <!-- languages menu list -->
    <v-list dense nav>
      <v-list-item
        v-for="(item, index) in menu"
        :key="index"
        :exact="item.exact"
        :disabled="item.disabled"
        @click="$emit('changeLang', item.value)"
      >
        <v-list-item-icon>
          <v-icon :class="{ 'primary--text': $lang.current_lang === item.value }">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title :class="{ 'primary--text': $lang.current_lang === item.value }">{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
/*
|---------------------------------------------------------------------
| Toolbar User Component
|---------------------------------------------------------------------
|
| Quickmenu for user menu shortcuts on the toolbar
|
*/
export default {
  data() {
    return {
      menu: [
        { icon: 'mdi-earth', text: 'English', value: 'en' },
        { icon: 'mdi-earth', text: 'Deutsch', value: 'de' }
      ]
    }
  }
}
</script>
