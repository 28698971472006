import Vue from 'vue'

// eslint-disable-next-line consistent-return
Vue.filter('unitFormatter', (value, oldUnit, newUnit) => {
  if (!value) return ''

  switch (oldUnit) {
  case 'MSec':
    if (newUnit === 'Sec') return value / 1000 < 1 ? 1 : (value / 1000).toFixed()
    if (newUnit === 'Min') return value / 1000 / 60 < 1 ? 1 : (value / 1000 / 60).toFixed()
    if (newUnit === 'Hr') return value / 1000 / 60 / 60 < 1 ? 1 : (value / 1000 / 60 / 60).toFixed()
    if (newUnit === 'Day') return value / 1000 / 60 / 60 / 24 < 1 ? 1 : (value / 1000 / 60 / 60 / 24).toFixed()

    return value
  case 'Sec':
    if (newUnit === 'MSec') return (value * 1000).toFixed()
    if (newUnit === 'Min') return value / 60 < 1 ? 1 : (value / 60).toFixed()
    if (newUnit === 'Hr') return value / 60 / 60 < 1 ? 1 : (value / 60 / 60).toFixed()
    if (newUnit === 'Day') return value / 60 / 60 / 24 < 1 ? 1 : (value / 60 / 60 / 24).toFixed()

    return value
  case 'Min':
    if (newUnit === 'MSec') return (value * 60 * 1000).toFixed()
    if (newUnit === 'Sec') return (value * 60).toFixed()
    if (newUnit === 'Hr') return value / 60 < 1 ? 1 : (value / 60).toFixed()
    if (newUnit === 'Day') return value / 60 / 24 < 1 ? 1 : (value / 60 / 24).toFixed()

    return value
  case 'Hr':
    if (newUnit === 'MSec') return (value * 60 * 60 * 1000).toFixed()
    if (newUnit === 'Sec') return (value * 60 * 60).toFixed()
    if (newUnit === 'Min') return (value * 60).toFixed()
    if (newUnit === 'Day') return value / 24 < 1 ? 1 : (value / 24).toFixed()

    return value.toFixed()
  case 'Day':
    if (newUnit === 'MSec') return (value * 24 * 60 * 60 * 1000).toFixed()
    if (newUnit === 'Sec') return (value * 24 * 60 * 60).toFixed()
    if (newUnit === 'Min') return (value * 24 * 60).toFixed()
    if (newUnit === 'Hr') return (value * 24).toFixed()

    return value.toFixed()
  default:
    return value.toFixed()
  }
})
