import Vue from 'vue'
import { formatISO } from 'date-fns'

Vue.filter('formatDateIsoEnd', (value) => {
  if (value) {
    const tempVal = value.split('-')

    if (tempVal.length === 3) {

      const temp = formatISO(new Date(tempVal[0], Number(tempVal[1]) - 1, tempVal[2], 23, 59, 59)).split('+')

      return `${temp[0]}.999+${temp[1]}`
    }
  }

  return ''
})
