import Vue from 'vue'

// eslint-disable-next-line consistent-return
Vue.filter('sizeUnitFormatter', (value, oldUnit, newUnit) => {
  if (!value) return ''

  switch (oldUnit) {
  case 'Bytes':
    if (newUnit === 'Kilobytes') return (value / 1024).toFixed()
    if (newUnit === 'Megabytes') return value / 1024 / 1024 < 1 ? 1 : (value / 1024).toFixed()

    return value
  case 'Kilobytes':
    if (newUnit === 'Bytes') return (value * 1024).toFixed()
    if (newUnit === 'Megabytes') return value / 1024 < 1 ? 1 : (value / 1024).toFixed()

    return value
  case 'Megabytes':
    if (newUnit === 'Bytes') return (value * 1024 * 1024).toFixed()
    if (newUnit === 'Kilobytes') return (value * 1024).toFixed()

    return value.toFixed()
  default:
    return value.toFixed()
  }
})
